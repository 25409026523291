import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {sortArrayByProperty} from 'helpers/array-helper';
import {gameUiTexts } from 'data/ui-texts';
import {regionsData} from 'data/regions-data';
import './highscore.scss';

const Highscore = ({deviceInfo, userData, gameData, handleGoToPrevPage}) => {
	/* Get region data */
	const regionData = regionsData.find((region) => {return region.id === userData.regionId;});

	/* Sorted users */
	const [sortedUsers, setSortedUsers] = useState([]);

	/* Game status */
	const gameIsSwitchedOff = (gameData.isSwitchedOff === true ? true : false);

	useEffect(() => {
		/* Component mounted, subscribe to users data */
		const db = firebase.firestore();
		const unsubscribeUsersData = db.collection(appConfig.usersDbName)
			.where('role', '==', 'player')
			.where('regionId', '==', userData.regionId)
			.onSnapshot((querySnapshot) => {
				let usersData = [];
				querySnapshot.forEach((doc) => {
					let points = 0;
					if (doc.data().modules && doc.data().modules.length > 0) {
						doc.data().modules.forEach((module) => {
							if (module.maxPoints) points += module.maxPoints;
						});
					}
					const name = (doc.data().name ? doc.data().name : doc.data().email);
					usersData.push({
						id: doc.id, 
						name: name, 
						nameSort: name.toLowerCase(),
						points
					});
				});

				usersData = sortArrayByProperty(usersData, 'points', 'DESC', 'nameSort', 'ASC');
				// if (usersData.length > 10) usersData = usersData.slice(0, 10);
				setSortedUsers(usersData);
			},
			(error) => {
				/* Error: Could not get users data */
				console.error('Could not get users data: ', error);
			});
		
		
		return () => {
			/* Component will unmount, cancel all subscriptions */
			if (unsubscribeUsersData) unsubscribeUsersData();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Get user's index on highscore list */
	const userHighscoreIndex = sortedUsers.findIndex((user) => {return user.id === userData.id;});

	/* Only show user's points at bottom if they are player nd not in top 10 */
	const showUserHighscore = (userHighscoreIndex >= 0 && userHighscoreIndex > 9 ? true : false);

	return (
		<div className={'Highscore ' + deviceInfo.orientation}>
			<div className="Highscore-prevBtn" onClick={() => {handleGoToPrevPage();}} />
			<div className="Highscore-content">
				<div className="Highscore-text">
					<span>{gameUiTexts.topPlayers}</span>
					<span>{regionData.name}</span>
				</div>
				{gameIsSwitchedOff && <div className="Highscore-gameStatusInfo">
					<span>{gameUiTexts.gameIsSwitchedOff}</span>
				</div>}
				{(sortedUsers.length > 0 && !gameIsSwitchedOff) && 
					<div className={'Highscore-table' + (showUserHighscore ? ' showUserHighscore' : '')}>
						{sortedUsers.map((user, index) => {
							if (index > 9) return null;
							return (
								<div 
									key={index} 
									className={'Highscore-row' + (index === userHighscoreIndex ? ' user' : '')}
								>
									<span>{index + 1}</span>
									<span>{user.name}</span>
									{/* <span>{user.points}</span> */}
								</div>
							);
						})}
					</div>
				}
				{showUserHighscore && 
					<div className="Highscore-userScore">
						<div className="Highscore-userRow">
							<span>{userHighscoreIndex + 1}</span>
							<span>{sortedUsers[userHighscoreIndex].name} <span>({gameUiTexts.you})</span></span>
							{/* <span>{sortedUsers[userHighscoreIndex].points}</span> */}
						</div>
					</div>
				}
			</div>
		</div>
	);
};

Highscore.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	handleGoToPrevPage: PropTypes.func.isRequired,
};

export default Highscore;
