import React from 'react';
import PropTypes from 'prop-types';
import {regionsData} from 'data/regions-data';
import {facilitatorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './facilitator-game-winner.scss';

const FacilitatorGameWinner = ({regionId, setShowDrawWinnerPopup}) => {

	const regionData = regionsData.find((r) => {return r.id === regionId;});
	
	return (
		<div className="FacilitatorGameWinner">
			<div className="FacilitatorGameWinner-title">
				<span>{facilitatorUiTexts.drawWinnerFrom} {regionData.name}</span>
			</div>
			<div className="FacilitatorGameWinner-button">
				<Button 
					classes={['green']}
					text={facilitatorUiTexts.drawWinner} 
					onClick={() => {setShowDrawWinnerPopup(true);}}
				/>
			</div>
		</div>
	);
};

FacilitatorGameWinner.propTypes = {
	regionId: PropTypes.string.isRequired,
	setShowDrawWinnerPopup: PropTypes.func.isRequired
};

export default FacilitatorGameWinner;
