import PropTypes from 'prop-types';
import {modulesData} from 'data/modules-data';
import './progress.scss';

const Progress = ({moduleId, taskId}) => {

	let taskIndex = 0;
	let solvableTasks = [];

	const moduleData = modulesData.find((module) => {return module.id === moduleId;});
	if (moduleData && moduleData.tasks) {
		solvableTasks = moduleData.tasks.filter((task) => {return task.isSolveToContinue === true;});
		taskIndex = solvableTasks.findIndex((task) => {return task.id === taskId;}) + 1;
	}
	return (
		<div className="Progress">
			<span>{taskIndex}/{solvableTasks.length}</span>
		</div>
	);
};

Progress.propTypes = {
	moduleId: PropTypes.string.isRequired,
	taskId: PropTypes.string.isRequired,

};


export default Progress;
