import {modulesData} from 'data/modules-data';

/**
 * Calculate average time spent in a module 
 * (include completed sessions only)
 * @param {array} players 
 * @param {string} moduleId 
 * @returns 
 */
export function getAverageTimeSpent(players, moduleId) {
	let totalSecondsSpent = 0;
	let counter = 0;
	players.forEach((player) => {
		if (player.modules && player.modules.length > 0) {
			const playerModuleData = player.modules.find((module) => {return module.moduleId === moduleId;});
			if (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0) {
				playerModuleData.sessions.forEach((session) => {
					if (session.isCompleted === true) { // TODO - ok condition?
						const secondsSpent = (session.milisecondsPlayed 
							? Math.floor(session.milisecondsPlayed / 1000.) 
							: 0
						);
						if (secondsSpent > 0) {
							totalSecondsSpent += secondsSpent;
							counter += 1;
						}
					}
				});
			}
		}
	});

	const avrSecondsSpent = (counter > 0 ? Math.floor(totalSecondsSpent / counter) : 0);
	const avrMinutesSpent = (avrSecondsSpent / 60.).toFixed(1);
	return avrMinutesSpent;
}

/**
 * Calculate average number of mistakes in a module 
 * (include completed sessions only) 
 * @param {array} players 
 * @param {string} moduleId 
 */
export function getAverageMistakes(players, moduleId) {
	let totalMistakes = 0;
	let counter = 0;

	const moduleData = modulesData.find((m) => {return m.id === moduleId;});

	if (moduleData && moduleData.tasks && moduleData.tasks.length > 0) {
		players.forEach((player) => {
			if (player.modules && player.modules.length > 0) {
				const playerModuleData = player.modules.find((module) => {return module.moduleId === moduleId;});
				if (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0) {
					playerModuleData.sessions.forEach((session) => {
						if (session.isCompleted === true) { // TODO - ok condition?
							moduleData.tasks.forEach((taskData) => {
								if (taskData.isSolveToContinue === false) return;
								const playerTaskData = (taskData.taskId && session.tasks 
									?	session.tasks.find((t) => {return t.taskId === taskData.taskId;})
									: null
								);
								if (playerTaskData && playerTaskData.hasOwnProperty('errors')) {
									counter += 1;
									if (playerTaskData.errors > 0) totalMistakes += playerTaskData.errors;
								}
							});
						}
					});
				}
			}
		});
	}

	const avrMistakes = (counter > 0 ? (totalMistakes / counter).toFixed(1) : '0.0');
	return avrMistakes;
}

/**
 * Calculate average number of mistakes / perfect scores in specific task 
 * (include completed sessions only) 
 * @param {array} players 
 * @param {string} moduleId 
 */
export function getAverageMistakesAndPerfectScores(players, moduleId, taskId) {
	let totalMistakes = 0;
	let totalPerfectScores = 0;
	let counter = 0;

	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	const taskData = (moduleData && moduleData.tasks 
		? moduleData.tasks.find((t) => {return t.taskId === taskId;})
		: null
	);

	if (taskData) {
		players.forEach((player) => {
			if (player.modules && player.modules.length > 0) {
				const playerModuleData = player.modules.find((module) => {return module.moduleId === moduleId;});
				if (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0) {
					playerModuleData.sessions.forEach((session) => {
						if (session.isCompleted === true) { // TODO - ok condition?
							if (taskData.isSolveToContinue === false) return;
							const playerTaskData = (taskData.taskId && session.tasks 
								?	session.tasks.find((t) => {return t.taskId === taskData.taskId;})
								: null
							);
							if (playerTaskData && playerTaskData.hasOwnProperty('errors')) {
								counter += 1;
								if (playerTaskData.errors > 0) {
									totalMistakes += playerTaskData.errors;
								} else {
									totalPerfectScores += 1;
								}
							}
						}
					});
				}
			}
		});
	}

	const avrMistakes = (counter > 0 ? (totalMistakes / counter).toFixed(1) : '0.0');
	const avrPerfectScores = (counter > 0 ? Math.round(totalPerfectScores / counter * 100) + '%' : '0%');
	return {avrMistakes, avrPerfectScores};
}


export function getPerfectScores(players, moduleId) {
}