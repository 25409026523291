import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {regionsData} from 'data/regions-data';
import {renderMarkdown} from 'helpers/text-helper';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './select-region.scss';

const SelectRegion = ({deviceInfo, userData, selectRegion}) => {
	
	const [regionId, setRegionId] = useState((userData.regionId ? userData.regionId : null));
	const [isUpdatingRegion, setIsUpdatingRegion] = useState(false);
	const handleSelectRegion = () => {
		if (!regionId || isUpdatingRegion) return;
		setIsUpdatingRegion(true);
		selectRegion(regionId);
	};

	return (
		<div className={'SelectRegion ' + deviceInfo.orientation}>
			<Logo />
			<div className="SelectRegion-title">{renderMarkdown(generalUiTexts.gameTitle)}</div>
			<div className="SelectRegion-text"><span>{generalUiTexts.selectLocation}</span></div>
			<div className="SelectRegion-regions">
				{regionsData.map((region) => {
					return (
						<div 
							key={region.id} 
							className={'SelectRegion-region' + (regionId === region.id ? ' selected' : '')}
							onClick={() => {setRegionId(region.id);}}
						>
							<span>{region.name}</span>
						</div>
					);
				})}
			</div>
			<div className="SelectRegion-okBtn">
				<Button 
					isDisabled={(regionId === null)}
					isLoading={isUpdatingRegion}
					classes={['white', 'confirm']} 
					text={generalUiTexts.ok} 
					onClick={()=>{handleSelectRegion();}} 
				/>
			</div>
		</div>
	);
};

SelectRegion.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	selectRegion: PropTypes.func.isRequired,
};

export default SelectRegion;
