const imagesData = [
	'background-shapes.svg',
	'characters/benjamin-1.svg',
	'characters/manager-1.svg',
	'characters/manager-2.svg',
	'characters/person-1.svg',
	'characters/person-2.svg',
	'dev/icon-menu.svg',
	'icon-arrow-back.svg',
	'icon-close.svg',
	'icon-home.svg',
	'icon-loading.svg',
	'icon-lock.svg',
	'icon-logout.svg',
	'icon-prev.svg',
	'icon-region.svg',
	'icon-trophy.svg',
	'logo-facilitator.svg',
	'logo.svg',
	'modules/icon-arrow-right.svg',
	'modules/icon-check.svg',
	'modules/icon-check2.svg',
	'modules/icon-down.svg',
	'modules/icon-error.svg',
	'modules/icon-move.svg',
	'modules/icon-next.svg',
	'modules/icon-up-down.svg',
	'modules/tasks/branching-story/icon-arrow-down.svg',
	'modules/tasks/dialogue/icon-next.svg',
	'modules/tasks/dialogue/icon-prev.svg',
	'modules/tasks/dialogue/icon-select-answer.svg',
	'modules/tasks/multiple-choice/game-1-email-wrong-address/option-1.svg',
	'modules/tasks/multiple-choice/game-1-email-wrong-address/option-2.svg',
	'modules/tasks/multiple-choice/game-1-email-wrong-address/option-3.svg',
	'modules/tasks/task-intro/benjamin.svg',
	'modules/tasks/task-intro/box.svg',
	'modules/tasks/task-intro/button.svg',
	'modules/tasks/task-intro/documents.svg',
	'modules/tasks/task-intro/email.svg',
	'modules/tasks/task-intro/folder-firstaid.svg',
	'modules/tasks/task-intro/folder-lock.svg',
	'modules/tasks/task-intro/monitor.svg',
	'trophy.svg'
];

export {
	imagesData
};