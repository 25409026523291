import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {gameUiTexts} from 'data/ui-texts';
import './task-intro.scss';

const TaskIntro = ({showNumberOfAnswersToSelect, numberOfCorrectAnswers, text, image}) => {
	const showRequiredNumberOfAnswers = (
		showNumberOfAnswersToSelect && 
		numberOfCorrectAnswers && 
		(numberOfCorrectAnswers > 0) ? true : false
	);

	return (
		<div className={'TaskIntro' + (image ? ' ' + image : '')}>
			<div className="TaskIntro-text">
				{text && renderMarkdown(text)}
				{showRequiredNumberOfAnswers && 
					<span>
						{gameUiTexts.choose} {numberOfCorrectAnswers} {(numberOfCorrectAnswers > 1 
							? gameUiTexts.options : gameUiTexts.option)}:
					</span>
				}
			</div>
			{image && <div className="TaskIntro-image" />}
		</div>
	);
};

TaskIntro.propTypes = {
	showNumberOfAnswersToSelect: PropTypes.bool,
	numberOfCorrectAnswers: PropTypes.number,
	text: PropTypes.string,
	image: PropTypes.string
};

export default TaskIntro;
