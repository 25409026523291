import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import Loading from 'components/loading/loading';
import Facilitator from 'components/facilitator/facilitator';

class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			players: []
		};
		this.unsubscribePlayers = null;	
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Subscribe to players */
		this.subscribeToPlayers().then((response) => {
			this.setState({isLoading: false});
			if (response.status !== 'success') {
				console.log('Error subscribing to players: ', response.error);
			}
		});
	}


	/**
	 * Subscribe to players
	 * @returns 
	 */
	subscribeToPlayers = (gameId) => {
		/* Cancel previous subscribtion */
		if (this.unsubscribePlayers !== null) this.unsubscribePlayers();

		/* Subscribe to players */
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const query = db.collection(appConfig.usersDbName).where('role', '==', 'player');
			this.unsubscribePlayers = query.onSnapshot((querySnapshot) => {
				let players = [];
				querySnapshot.forEach((doc) => {players.push({id: doc.id, ...doc.data()});});
				this.setState({players}, () => {resolve({status: 'success'});});
			},
			(error) => {
				/* Error: could not get  players */
				console.error('could not get players: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Update game data
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGameData = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update game data */
		const db = firebase.firestore();
		return db.collection(appConfig.gamesDbName).doc('game-1').update(updates);
	};

	/**
	 * Draw winner in region
	 * @param {string} regionId 
	 * @returns 
	 */
	drawWinnersInRegion = (regionId) => {
		return new Promise((resolve) => {
			apiHelper('user/draw-winners-from-region', {regionId: regionId}).then((response) => {
				// Success
				resolve({status: 'success', winnerIds: response.winnerIds});
			},
			(rejection) => {
				// Error
				console.log(rejection);
				resolve({status: 'error', error: rejection});
			}
			);
		});
	};

	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading type="loading-facilitator-data" deviceInfo={this.props.deviceInfo} />
			);
		}

		return (		
			<Facilitator 
				deviceInfo={this.props.deviceInfo}
				userData={this.props.userData}
				gameData={this.props.gameData}
				players={this.state.players}
				updateGameData={this.updateGameData}
				drawWinnersInRegion={this.drawWinnersInRegion}
				handleLogout={this.props.handleLogout}
			/>
		);
	}
}

FacilitatorController.propTypes = {
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default FacilitatorController;
