import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {loginUiTexts, generalUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './login.scss';

const Login = ({deviceInfo, handleLogin, handleFakeLogin}) => {
	const showPseudoLogins = (appConfig.env === 'development' || appConfig.env === 'test' ? true : false);
	return (
		<div className={'Login ' + deviceInfo.orientation}>
			<Logo />
			<div className="Login-title">{renderMarkdown(generalUiTexts.gameTitle)}</div>
			<div className="Login-loginBtn">
				<Button 
					classes={['white', 'uppercase']} 
					text={loginUiTexts.login}
					onClick={() => {handleLogin();}}
				/>
			</div>
			{showPseudoLogins && <div className="Login-pseudoLogins">
				<Button 
					classes={['login']} 
					text={'Facilitator 1'} 
					onClick={() => {handleFakeLogin('facilitator-1');}}
				/>
				<Button 
					classes={['login']} 
					text={'Facilitator 2'} 
					onClick={() => {handleFakeLogin('facilitator-2');}}
				/>
				{Array.from({ length: 10 }, (_, i) => {
					const playerNumber = i + 1;
					let playerName = null;
					if (playerNumber === 1) playerName = 'Diana';
					if (playerNumber === 2) playerName = 'Mads';
					if (playerNumber === 3) playerName = 'Chandra';
					if (playerNumber === 4) playerName = 'Henning';
					return (
						<Button 
							key={i}
							classes={['login']} 
							text={'Player ' + (playerNumber) + (playerName ? ' (' + playerName + ')' : '')} 
							onClick={() => {handleFakeLogin('player-' + playerNumber);}}
						/>
						
					);
				})}				
			</div>}
		</div>
	);
};

Login.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	handleLogin: PropTypes.func.isRequired,
	handleFakeLogin: PropTypes.func.isRequired,
};

export default Login;
