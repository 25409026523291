import React from 'react';
import PropTypes from 'prop-types';
import {getAverageMistakesAndPerfectScores} from 'helpers/facilitator-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import {modulesData} from 'data/modules-data';
import './facilitator-region-stats.scss';

const FacilitatorRegionStats = ({moduleId, regionId, players, setTaskId}) => {
	/* Get active players in region */
	const playersInRegion = players.filter((player) => {
		return (
			(player.regionId === regionId || regionId === 'all') && 
			player.modules &&
			player.modules.length > 0 &&
			player.modules.some((m) => {return m.moduleId === moduleId;})
		);
	});

	/* Get module and module tasks data */
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	const moduleTasks = (moduleData && moduleData.tasks && moduleData.tasks.length > 0
		? moduleData.tasks.filter((t) => {return t.isSolveToContinue === true;})
		: []
	);

	return (
		<div className="FacilitatorRegionStats">
			<div className="FacilitatorRegionStats-table">
				<div className='FacilitatorRegionStats-tableHeader'>
					<div className='FacilitatorRegionStats-tableCell'>{facilitatorUiTexts.task}</div>
					<div className='FacilitatorRegionStats-tableCell'>{facilitatorUiTexts.type}</div>
					<div className='FacilitatorRegionStats-tableCell'>{facilitatorUiTexts.avrMistakes}</div>
					<div className='FacilitatorRegionStats-tableCell'>{facilitatorUiTexts.perfectScores}</div>
				</div>
				{moduleTasks.map((task) => {
					const {avrMistakes, avrPerfectScores} = 
						getAverageMistakesAndPerfectScores(playersInRegion, moduleId, task.taskId);
					return (
						<div 
							key={task.id} 
							className="FacilitatorRegionStats-tableRow"
							onClick={() => {setTaskId(task.taskId);}}
						>
							<div className='FacilitatorRegionStats-tableCell'>{task.id}</div>
							<div className='FacilitatorRegionStats-tableCell'>
								{task.type + (task.subtype ? ' (' + task.subtype + ')' : '')}
							</div>
							<div className='FacilitatorRegionStats-tableCell'>{avrMistakes}</div>
							<div className='FacilitatorRegionStats-tableCell'>{avrPerfectScores}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

FacilitatorRegionStats.propTypes = {
	moduleId: PropTypes.string.isRequired,
	regionId: PropTypes.string.isRequired,
	players: PropTypes.array.isRequired,
	setTaskId: PropTypes.func.isRequired
};

export default FacilitatorRegionStats;
