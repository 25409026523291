import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './select-section.scss';

const SelectSection = ({deviceInfo, handleGoToSection, handleLogout}) => {

	return (
		<div className={'SelectSection ' + deviceInfo.orientation}>
			<div className="SelectSection-logo"><Logo type='facilitator' /></div>
			
			<div className="SelectSection-section">
				<div className="SelectSection-sectionText">
					<span>{generalUiTexts.goToGame}</span>
				</div>
				<div className="SelectSection-sectionBtn">
					<Button 
						classes={['selectSection']}
						text={generalUiTexts.ok} 
						onClick={() => {handleGoToSection('game');}}
					/>	
				</div>
			</div>
			<div className="SelectSection-or"><span>{generalUiTexts.or}</span></div>
			<div className="SelectSection-section">
				<div className="SelectSection-sectionText">
					<span>{generalUiTexts.goToFacilitator}</span>
				</div>
				<div className="SelectSection-sectionBtn">
					<Button 
						classes={['selectSection']} 
						text={generalUiTexts.ok} 
						onClick={() => {handleGoToSection('facilitator');}}
					/>
				</div>
			</div>
			<div className="SelectSection-logoutBtn" onClick={() => {handleLogout();}}/>
		</div>
	);
};

SelectSection.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	handleGoToSection: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default SelectSection;
