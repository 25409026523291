import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/ui/logo/logo';
import './facilitator-header.scss';

const FacilitatorHeader = ({userData, handleLogout}) => {
	return (
		<div className="FacilitatorHeader">
			{/* Logo */}
			<div className="FacilitatorHeader-logo"><Logo type="facilitator" /></div>

			{/* Logout / info buttons */}
			<div className="FacilitatorHeader-logoutBtn" onClick={() => {handleLogout();}}/>
		</div>
	);

};

FacilitatorHeader.propTypes = {
	userData: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default FacilitatorHeader;
