import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {modulesData} from 'data/modules-data';


/**
 * Create new module in player modules array
 * @param {string} moduleId 
 * @returns 
 */
export function createNewPlayerModule(moduleId) {
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	const	taskId = (moduleData.tasks && moduleData.tasks.length > 0 ? moduleData.tasks[0].id : null);
	const sessionTemplate = {
		isCompleted: false,
		started: dayjs(new Date()).format('YYYY-MM-DD'),
		currentTaskId: taskId,
		points: 0,
		milisecondsPlayed: 0,
		tasks: []
	};
	const playerModule = {
		moduleId: moduleId,
		// maxStars: 0,
		maxPoints: 0,
		sessions: [sessionTemplate],
	};
	return playerModule;		
}

/**
 * Get player modules data
 * @param {object} playerData 
 * @returns {array}
 */
export function getPlayerModulesData(playerData) {
	const playerModulesData = (playerData && playerData.modules ? [...playerData.modules] : []);
	return playerModulesData;
};

/**
 * Get player data for current module session
 * @param {array} playerModulesData 
 * @param {string} moduleId 
 * @returns 
 */
export function getPlayerModuleSessionData(playerData, moduleId) {
	const playerModulesData = getPlayerModulesData(playerData);
	const playerModuleData = playerModulesData.find((m) => {return m.moduleId === moduleId;});
	if (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0) {
		return playerModuleData.sessions[playerModuleData.sessions.length - 1];
	}
	return null;
}

/**
 * Check if module session is completed
 * @param {object} playerModuleSessionData 
 * @returns 
 */
export function checkIfModuleSessionIsCompleted(playerModuleSessionData) {
	let sessionIsCompleted = false;
	if (playerModuleSessionData && playerModuleSessionData.isCompleted === true) sessionIsCompleted = true;
	return sessionIsCompleted;
}

/**
 * Check if module is completed
 * @param {object} playerModuleData 
 * @returns 
 */
export function checkIfModuleIsCompleted(playerModuleData) {
	const moduleIsCompleted = (
		playerModuleData && 
		playerModuleData.sessions && 
		(
			playerModuleData.sessions.length > 1 ||
			(
				playerModuleData.sessions.length > 0 && 
				checkIfModuleSessionIsCompleted(playerModuleData.sessions[0])
			)
		) ? true : false
	);
	return moduleIsCompleted;
}

/**
 * Check if module is locked
 * @param {object} moduleData 
 * @param {object} userData 
 * @returns 
 */
export function checkIfModuleIsLocked(moduleData, userData)  {
	let isLocked = false;

	if (moduleData.unlockedByModuleIds && moduleData.unlockedByModuleIds.length > 0) {
		isLocked = true;
		if (
			userData && 
			userData.modules && 
			userData.modules.some((m) => {
				return (
					moduleData.unlockedByModuleIds.indexOf(m.moduleId) >= 0 && 
					m.sessions && 
					m.sessions.length > 0 &&
					checkIfModuleIsCompleted(m)
				);
			})) isLocked = false;
	}

	return isLocked;
}


/**
 * Start new module session
 * @param {string} moduleId 
 * @param {object} playerData 
 * @returns 
 */
export function starNewSession(moduleId, playerData) {
	const playerModulesData = getPlayerModulesData(playerData);
	const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === moduleId;});
	if (moduleIndex < 0) return null;
	if (!playerModulesData[moduleIndex].hasOwnProperty('sessions')) return null;
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	const	taskId = (moduleData.tasks && moduleData.tasks.length > 0 ? moduleData.tasks[0].id : null);
	const sessionTemplate = {
		isCompleted: false,
		started: dayjs(new Date()).format('YYYY-MM-DD'),
		currentTaskId: taskId,
		points: 0,
		milisecondsPlayed: 0,
		tasks: []
	};
	playerModulesData[moduleIndex].sessions.push(sessionTemplate);

	return playerModulesData;
}

/**
 * Get number of miliseconds since last activity timestamp
 * @param {number} timestamp 
 * @returns 
 */
export function getTimeSinceLastActivity(timestamp) {
	/* Get difference in miliseconds */
	const seconds = Date.now() - timestamp;

	/* Return differnce (max value is inactivity limit) */
	return Math.min(seconds, appConfig.inactivityLimitSeconds * 1000);
};

/**
 * Get the last played taskId of the module (null if player has just started the module)
 * @param {string} moduleId 
 * @param {object} playerData 
 * @returns 
 */
export function getLastPlayedTaskId(moduleId, playerData) {
	let taskId = null;
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	if (moduleData.tasks && moduleData.tasks.length > 0) {
		/* First task in module */
		taskId = moduleData.tasks[0].id;
		const playerModulesData = getPlayerModulesData(playerData);
		const playerModuleData = playerModulesData.find((m) => {return m.moduleId === moduleId;});
		if (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0) {
			/* Player is already playing, get their last visited task */
			if (playerModuleData.sessions[playerModuleData.sessions.length - 1].currentTaskId && 
					moduleData.tasks.some((m) => {
						return m.id === playerModuleData.sessions[playerModuleData.sessions.length - 1].currentTaskId;
					})
			) {
				taskId = playerModuleData.sessions[playerModuleData.sessions.length - 1].currentTaskId;
			}
		}
	}

	return taskId;
}

/**
 * Check if all the tasks in a module are completed
 * @param {object} playerModuleData 
 * @returns 
 */
export function checkIfAllTasksAreCompleted(moduleId, playerModuleSessionData) {
	let tasksAreCompleted = false;

	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	if (moduleData && moduleData.tasks && moduleData.tasks.length > 0) {
		tasksAreCompleted = true;
		moduleData.tasks.forEach((task) => {
			if (tasksAreCompleted === false) return;
			if (task.isSolveToContinue === false) return;
			if (!task.taskId) return;
			if (
				!playerModuleSessionData.hasOwnProperty('tasks') ||
					!playerModuleSessionData.tasks.some((t) => {
						return (t.taskId === task.taskId && t.isCompleted === true);
					})
			) {
				tasksAreCompleted = false;
			}
		});
	}

	return tasksAreCompleted;
};

/**
 * Get new streak type/value depending on player answer
 * @param {string} streakType 
 * @param {string} streakValue 
 * @param {bool} isCorrectAnswer 
 */
export function getNewStreakData(streakType, streakValue, isCorrectAnswer) {
	/* Update streak type and value */
	let newStreakType = streakType;
	let newStreakValue = streakValue;
	
	if (isCorrectAnswer) {
		if (streakType === 'success') {
			/* Player continued success streak */
			newStreakValue += 1;
		} else {
			/* Player started success streak */
			newStreakValue = 1;
			newStreakType = 'success';
		}
	} else {
		if (streakType === 'error') {
			/* Player continued error streak */
			newStreakValue += 1;
		} else {
			/* Player started error streak */
			newStreakValue = 1;
			newStreakType = 'error';
		}
	}

	return {newStreakType, newStreakValue};
}