import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
// import Character from 'components/ui/character/character';
import './branching-story-info.scss';

const BranchingStoryInfo = ({storyStepData}) => {
	if (!storyStepData) return null;
	
	return (
		<div className="BranchingStoryInfo">
			{/* Title and text */}
			{(storyStepData.title || storyStepData.text) && 
				<div className={'BranchingStoryInfo-textBox' + (storyStepData.textType === 'speech' ? ' bubble' : '')}>
					{storyStepData.title && 
						<div className="BranchingStoryInfo-title">{storyStepData.title}</div>}
					{storyStepData.text && 
						<div className="BranchingStoryInfo-text">{renderMarkdown(storyStepData.text)}</div>}
				</div>
			}

			{/* Images */}
			{storyStepData.images && storyStepData.images.map((image, index) => {
				// if (image.type === 'character') {
				// 	return (
				// 		<div key={index} className={'StoryScreen-character ' + image.characterId}>
				// 			<Character 
				// 				page="branching-story" 
				// 				characterId={image.characterId}
				// 			/>
				// 		</div>
				// 	);
				// }
				return null;
			})}
		</div>
	);
};

BranchingStoryInfo.propTypes = {
	storyStepData: PropTypes.object.isRequired
};

export default BranchingStoryInfo;
