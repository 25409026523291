import React from 'react';
import PropTypes from 'prop-types';
import {checkIfModuleIsCompleted} from 'helpers/game-helper';
import {getAverageTimeSpent, getAverageMistakes} from 'helpers/facilitator-helper';
import {facilitatorUiTexts} from 'data/ui-texts';
import FacilitatorDownloadStats from 'components/facilitator/facilitator-download-stats/facilitator-download-stats';
import './facilitator-overview-stats.scss';

const FacilitatorOverviewStats = ({moduleId, gameData, regionsList, players, updateGameData, setRegionId}) => {
	return (
		<div className="FacilitatorOverviewStats">

			{/* Region stats */}
			<div className="FacilitatorOverviewStats-table">
				<div className='FacilitatorOverviewStats-tableHeader'>
					<div className='FacilitatorOverviewStats-tableCell'>{facilitatorUiTexts.region}</div>
					<div className='FacilitatorOverviewStats-tableCell'>{facilitatorUiTexts.startedBy}</div>
					<div className='FacilitatorOverviewStats-tableCell'>{facilitatorUiTexts.completedBy}</div>
					<div className='FacilitatorOverviewStats-tableCell'>{facilitatorUiTexts.avrTimeSpent}</div>
					<div className='FacilitatorOverviewStats-tableCell'>{facilitatorUiTexts.avrMistakes}</div>
				</div>
				{regionsList.map((region) => {
					const playersInRegion = players.filter((player) => {
						return (
							(player.regionId === region.id || region.id === 'all') && 
							player.modules &&
							player.modules.length > 0 &&
							player.modules.some((m) => {return m.moduleId === moduleId;})
						);
					});
					const startedBy = playersInRegion.length;
					const completedBy = playersInRegion.filter((player) => {
						const playerModuleData = player.modules.find((m) => {return m.moduleId === moduleId;});
						return checkIfModuleIsCompleted(playerModuleData);
					}).length;
					const avrTimeSpent = getAverageTimeSpent(playersInRegion, moduleId);
					const avrMistakes = getAverageMistakes(playersInRegion, moduleId);
					return (
						<div 
							key={region.id} 
							onClick={() => {setRegionId(region.id);}} 
							className="FacilitatorOverviewStats-tableRow"
						>
							<div className='FacilitatorOverviewStats-tableCell'>{region.name}</div>
							<div className='FacilitatorOverviewStats-tableCell'>{startedBy}</div>
							<div className='FacilitatorOverviewStats-tableCell'>{completedBy}</div>
							<div className='FacilitatorOverviewStats-tableCell'>
								{avrTimeSpent + ' ' + facilitatorUiTexts.min}
							</div>
							<div className='FacilitatorOverviewStats-tableCell'>{avrMistakes}</div>
						</div>
					);
				})}
			</div>

			{/* Download not-completed players */}
			<div className='FacilitatorOverviewStats-downloadBtn'>
				<FacilitatorDownloadStats players={players} />
			</div>
		</div>
	);
};

FacilitatorOverviewStats.propTypes = {
	moduleId: PropTypes.string.isRequired,
	gameData: PropTypes.object,
	regionsList: PropTypes.array.isRequired,
	players: PropTypes.array.isRequired,
	updateGameData: PropTypes.func.isRequired,
	setRegionId: PropTypes.func.isRequired
};

export default FacilitatorOverviewStats;
