import React from 'react';
import PropTypes from 'prop-types';
import {facilitatorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './facilitator-game-status.scss';

const FacilitatorGameStatus = ({gameData, updateGameData}) => {
	/* Game status */
	const isSwitchedOff = (gameData.isSwitchedOff ? true : false);

	/**
	 * Toggle game status
	 */
	const toggleGameStatus = () => {
		updateGameData({isSwitchedOff: !isSwitchedOff});
	};

	
	return (
		<div className="FacilitatorGameStatus">
			<div className="FacilitatorGameStatus-button">
				<Button 
					text={(isSwitchedOff ? facilitatorUiTexts.off : facilitatorUiTexts.on)} 
					type="switch" 
					isChecked={!isSwitchedOff} 
					onClick={() => {toggleGameStatus();}}
				/>
			</div>
			<div className="FacilitatorGameStatus-title">
				<span>
					{isSwitchedOff ? facilitatorUiTexts.switchGameOn : facilitatorUiTexts.switchGameOff}:
				</span>
			</div>
			<div className="FacilitatorGameStatus-text">
				<span>{facilitatorUiTexts.gameStatusDescription}</span>
			</div>
		</div>

	);
};

FacilitatorGameStatus.propTypes = {
	gameData: PropTypes.object,
	updateGameData: PropTypes.func.isRequired
};

export default FacilitatorGameStatus;
