import React, {useState} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Login from 'components/login/login';
import UserController from 'components/users/user-controller';

const Auth = ({deviceInfo, scrollToTop}) => {
	
	const [fakeAuthData, setFakeAuthData] = useState(null);
	const [authData, setAuthData] = useState(null);

	/**
	 * Handle login
	 * @returns 
	 */
	const handleLogin = () => {
		const auth = firebase.auth();
		const provider = new firebase.auth.OAuthProvider('microsoft.com');
		provider.setCustomParameters({
			tenant: '9a88a419-24b9-401f-8557-e155db7ae966'
		});
		auth.signInWithPopup(provider).then((data) => {
			setAuthData({id: data.user.uid, email: data.user.email, name: data.user.displayName});
		});
	};

	/**
	 * Handle logout
	 */
	const handleLogout = () => {
		if (authData) {
			firebase.auth().signOut().then(() => {
				console.log('hep');
				setAuthData(null);
			}).catch((error) => {
				console.error(error);
			});
		}

		if (fakeAuthData) {
			setFakeAuthData(null);
		}
	};

	/**
	 * "Fake" login (not using SSO)
	 * @param {string} userId 
	 */
	const handleFakeLogin = (userId) => {
		setFakeAuthData({id: userId, email: userId + '@cphgamelab.dk', name: userId});
	};



	if (authData || fakeAuthData) {
		return <UserController 
			authData={(authData ? authData : fakeAuthData)}	
			deviceInfo={deviceInfo} 
			scrollToTop={scrollToTop} 
			handleLogout={handleLogout}
		/>;
	};

	return <Login 
		handleLogin={handleLogin} 
		handleFakeLogin={handleFakeLogin}
		deviceInfo={deviceInfo}
	/>;
};

Auth.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired
};

export default Auth;
