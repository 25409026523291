import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import {checkIfModuleIsCompleted} from 'helpers/game-helper';
import {modulesData} from 'data/modules-data';
import {facilitatorUiTexts} from 'data/ui-texts';
import './facilitator-download-stats.scss';

const FacilitatorDownloadStats = ({players}) => {
	const columns = [
		{value: 'playerName', label: facilitatorUiTexts.name},
		{value: 'playerEmail', label: facilitatorUiTexts.email},
		{value: 'regionId', label: facilitatorUiTexts.region},
	];

	let sheets = [];
	modulesData.forEach((moduleData) => {
		let data = [];
		players.forEach((player) => {
			const isStarted = (
				player.role === 'player' &&
				player.modules &&
				player.modules.length > 0 &&
				player.modules.some((m) => {return m.moduleId === moduleData.id;})
			);
			if (isStarted) {
				const playerModuleData = player.modules.find((m) => {return m.moduleId === moduleData.id;});
				const isCompleted = checkIfModuleIsCompleted(playerModuleData);
				if (!isCompleted) {
					data.push({
						playerName: player.name,
						playerEmail: player.email,
						regionId: player.regionId
					});
				}
			}
		});	

		sheets.push({
			id: moduleData.id,
			name: moduleData.title,
			columns: columns,
			data: data
		});
	});

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	return (
		<ExcelFile element={<button className="FacilitatorDownloadStats">{facilitatorUiTexts.download}</button>}>
			{sheets.map((sheet, index) => {
				return (
					<ExcelSheet key={index} data={sheet.data} name={sheet.name}>
						{sheet.columns.map((column, cIndex) => {
							return <ExcelColumn key={cIndex} label={column.label} value={column.value} />;
						})}
					</ExcelSheet>
				);
			})}
		</ExcelFile>
	);
};

FacilitatorDownloadStats.propTypes = {
	players: PropTypes.array.isRequired,
};

export default FacilitatorDownloadStats;
