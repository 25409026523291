import PropTypes from 'prop-types';
import {loadingUiTexts} from 'data/ui-texts';
import './loading.scss';

const Loading = ({type, deviceInfo}) => {
	/* Get loading text */
	let text = loadingUiTexts.loading;
	if (type === 'loading-user-and-game-data') text = loadingUiTexts.loadingUserAndGameData;
	if (type === 'loading-facilitator-data') text = loadingUiTexts.loadingFacilitatorData;

	
	if (type === 'loading-user-data') text = loadingUiTexts.loadingUserData;
	if (type === 'loading-admin-data') text = loadingUiTexts.loadingAdminData;
	
	if (type === 'loading-game') text = loadingUiTexts.loadingGameData;
	if (type === 'loading-module') text = loadingUiTexts.loadingModule;

	return (
		<div className={'Loading ' + type + ' ' + deviceInfo.orientation}>
			<div className='Loading-text'><span>{text} ...</span></div>
		</div>
	);
};

Loading.propTypes = {
	type: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
};

export default Loading;
