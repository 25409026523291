import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {scrollTo} from 'helpers/scroll-helper';
import {renderMarkdown} from 'helpers/text-helper';
import {gameUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import Carousel from './carousel';
import './dialogue.scss';

const Dialogue = (props) => {
	const {
		deviceInfo,
		playerTaskData, 
		taskData,
		handleInstantTaskEffects, 
		updateLoggedTime,
		handleCompleteTask, 
		setShowPointsTrack,
		isFacilitator = false,
	} = props;

	/* Timeout */
	let timeout = null;	

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Show next step button (if next step is final page) */
	const [nextStepId, setNextStepId] = useState(null);

	/**
	 * Get first dialogue step id
	 * @returns {number} dialogueStepId
	 */
	const getFirstDialogueStepId = () => {
		if (taskData && taskData.steps && taskData.steps.length > 0) {
			if (isCompleted) return playerTaskData.lastStepId;
			return taskData.steps[0].id;
		}
		return null;
	};

	/**
	 * Prepare dialogue
	 * @returns {array} dialogueArr
	 */
	const prepareDialogue = () => {
		if (isCompleted) return [];
		const firstDialogueStepData = (taskData && taskData.steps && taskData.steps.length > 0 
			? taskData.steps[0] : null);
		let dialogueArr = [];
		if (firstDialogueStepData && firstDialogueStepData.type && firstDialogueStepData.text) {
			dialogueArr.push({
				person: 'customer', 
				character: firstDialogueStepData.person ? firstDialogueStepData.person : taskData.person1,
				image: firstDialogueStepData.image ? firstDialogueStepData.image : 'neutral',
				type: firstDialogueStepData.type, 
				text: firstDialogueStepData.text
			});
		}

		return dialogueArr;
	};

	/**
	 * Get points
	 * @returns {number} points
	 */
	const getPoints = () => {
		let points = null;
		if (isCompleted) points = playerTaskData.points;
		return points;
	};


	/* Track step id, dialogue array, show / hide options & points */
	const [dialogueStepId, setDialogueStepId] = useState(getFirstDialogueStepId());
	const [dialogueArr, setDialogueArr] = useState(prepareDialogue());
	const [showIntro, setShowIntro] = useState(true);
	const [showOptions, setShowOptions] = useState(true);
	const [points, setPoints] = useState(getPoints());

	/**
	 * Select an option (answer or action)
	 * @param {number} optionId 
	 */
	const selectOption = (optionId) => {
		/* Update logged time */
		updateLoggedTime();
		
		/* Facilitator cannot solve task */
		if (isFacilitator) return;

		const dialogueStepData = taskData.steps.find((s) => {return s.id === dialogueStepId;});
		const dialogueOptionData = (dialogueStepData && dialogueStepData.options
			? dialogueStepData.options.find((option) => {return option.id === optionId;})
			: null
		);
		if (dialogueOptionData) {			
			/* Update dialogue */
			let newDialogueArr = JSON.parse(JSON.stringify(dialogueArr));
			if (dialogueOptionData.dialogueEffects && dialogueOptionData.dialogueEffects.length > 0) {
				/* Use effects (dialogue and / or action) */
				dialogueOptionData.effects.forEach((dialogueEffect) => {
					newDialogueArr.push({
						person: 'player', 
						character: dialogueEffect.person ? dialogueEffect.person : taskData.person2,
						type: dialogueEffect.type, 
						text: dialogueEffect.text
					});
				});
			} else {
				/* Use default option text (dialogue or action) */
				if (dialogueStepData.optionType && dialogueOptionData.text) {
					newDialogueArr.push({
						person: 'player', 
						character: dialogueOptionData.person ? dialogueOptionData.person : taskData.person2,
						type: dialogueStepData.optionType, 
						text: dialogueOptionData.text
					});
				}
			}
			setDialogueArr(newDialogueArr);

			/* Effects */
			let newPoints = points;
			let instantEffects = [];
			if (dialogueOptionData.effects && dialogueOptionData.effects.length > 0) {
				dialogueOptionData.effects.forEach((effect) => {
					if (effect.type === 'points') {
						/* Effect: points */
						newPoints += effect.value;	
					} else {
						/* Effect: feedback, popup, special points, new avatar item */
						instantEffects.push(effect);
					}
				});
			}

			/* Update points */
			setPoints(newPoints);
			
			/* Instant effects */
			if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);

			/* Go to next step or stay at current */
			if (dialogueOptionData.nextStepId) {
				const nextDialogueStepData = taskData.steps.find((step) => {
					return step.id === dialogueOptionData.nextStepId;
				});
				if (nextDialogueStepData && nextDialogueStepData.isFinalStep) {
					/* Stay at the step before the final step until player clicks "next" button */
					setNextStepId(dialogueOptionData.nextStepId);
				} else {
					/* Go to next dialogue step */
					const nextDialogueStepId = dialogueOptionData.nextStepId;
					goToNextDialogueStep(nextDialogueStepId, newDialogueArr, newPoints);
				}
			} else {
				/* Stay at this step (selected option not approved) */
			}
		}
	};

	/**
	 * Go to next dialogue step
	 * @param {number} nextDialogueStepId 
	 * @param {array} newDialogueArr 
	 * @param {number} newPoints
	 */
	const goToNextDialogueStep = (nextDialogueStepId, newDialogueArr, newPoints) => {
		const nextDialogueStepData = taskData.steps.find((step) => {return step.id === nextDialogueStepId;});

		if (nextDialogueStepData) {
			/* Hide options */
			setShowOptions(false);

			/* Update dialogue */
			if (nextDialogueStepData && nextDialogueStepData.type && nextDialogueStepData.text) {
				newDialogueArr.push({
					person: 'customer', 
					character: nextDialogueStepData.person ? nextDialogueStepData.person : taskData.person1,
					image: nextDialogueStepData.image ? nextDialogueStepData.image : 'neutral',
					type: nextDialogueStepData.type, 
					text: nextDialogueStepData.text
				});
				setDialogueArr(newDialogueArr);
			}

			/* Effects */
			let totalPoints = newPoints;
			let instantEffects = [];
			if (nextDialogueStepData.effects && nextDialogueStepData.effects.length > 0) {
				nextDialogueStepData.effects.forEach((effect) => {
					if (effect.type === 'points') {
						/* Effect: points */
						totalPoints += effect.value;	
					} else {
						/* Effect: feedback, popup, special points, new avatar item */
						instantEffects.push(effect);
					}
				});
			}

			/* Update dialogue step id */
			setDialogueStepId(nextDialogueStepId);

			/* Update points */
			if (totalPoints !== points) setPoints(totalPoints);

			/* Instant effects */
			if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);

			/* Toggle points track */
			if (nextDialogueStepData && nextDialogueStepData.hasOwnProperty('showPoints')) {
				setShowPointsTrack(nextDialogueStepData.showPoints);
			}

			/* Check if task is completed */
			if (nextDialogueStepData && nextDialogueStepData.isFinalStep === true) {
				completeTask(nextDialogueStepData.id, totalPoints);
			}

			/* Show options */
			timeout = setTimeout(() => {setShowOptions(true);}, 100);

			/* Auto-nav to next dialogue step */
			if (
				nextDialogueStepData && 
					nextDialogueStepData.nextStepId && 
					nextDialogueStepData.autoContinue === true
			) {
				goToNextDialogueStep(nextDialogueStepData.nextStepId, newDialogueArr, totalPoints);
			}
		}
	};


	/**
	 * Complete task
	 * @param {string} lastDialogueStepId
	 * @param {number} newPoints
	 */
	const completeTask = (lastDialogueStepId, newPoints) => {
		/* Errors */
		const errors = 0;

		/* Save completed task */
		handleCompleteTask(
			'dialogue', 
			newPoints, 
			errors, 
			[],
			{lastStepId: lastDialogueStepId}
		);
	};


	/**
	 * Smooth scroll to dialogue bottom
	 */
	const scrollToBottom = () => {
		var div = document.getElementById('dialogueLines');
		if (div) {
			scrollTo(div, (div.scrollHeight - div.clientHeight), 0.1, null);
		}
	};

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
		// ...

		return () => {
			/* Component will unmount */
			if (timeout) clearTimeout(timeout);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		scrollToBottom();
	}, [dialogueArr]);

	/**
	 * New dialogue task, reset
	 */
	useEffect(() => {
		setDialogueStepId(getFirstDialogueStepId());
		setDialogueArr(prepareDialogue());
		setShowOptions(true);
		setPoints(getPoints());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/* Get current dialogue step data */
	const dialogueStepData = taskData.steps.find((s) => {return s.id === dialogueStepId;});	
	const isLastPage = (dialogueStepData && dialogueStepData.isFinalStep === true);


	/* Intro */
	if (!isCompleted && showIntro && !isFacilitator) {
		return (
			<div className="Dialogue intro">
				{taskData.introText && 
					<div className={'Dialogue-introText ' + taskData.introTextType}>
						{renderMarkdown(taskData.introText)}
					</div>
				}
				<div className="Dialogue-startBtn">
					<Button classes={['white']} text={gameUiTexts.go + '!'} onClick={() => {setShowIntro(false);}} />
				</div>
			</div>
		);
	}

	/* Result */
	if (isLastPage) {
		return (
			<div className="Dialogue result">
				<div className={'Dialogue-resultText ' + dialogueStepData.type}>{dialogueStepData.text}</div>
			</div>
		);
	}

	/* Dialogue */
	return (
		<div 
			className={'Dialogue'
				+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')}
		>
			<div className="Dialogue-wrap">
				{/* Dialogue (speech / action bubbles) */}
				<div className="Dialogue-dialogue">
					<div id="dialogueLines" className="Dialogue-lines">
						{dialogueArr.map((line, index) => {
							return (
								<div key={index} className={'Dialogue-line ' + line.person + ' ' + line.type}>
									<div className="Dialogue-bubble">
										<span>{line.text}</span>
									</div>
									<div className={'Dialogue-personAvatar ' + line.person
													+ ' '
													+ line.character 
													+ (line.image ? ' ' + line.image : '')
									}>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				{/* Bottom panel */}
				<div className="Dialogue-panel">
					{/* Options */}
					{
						(
							showOptions && dialogueStepData && !nextStepId && 
						dialogueStepData.options && dialogueStepData.options.length > 0
						) && 
						<div className={'Dialogue-options'}>
							<div className="Dialogue-optionsText"><span>{dialogueStepData.optionPrompt}</span></div>
							<div className="Dialogue-optionsCarousel">
								<Carousel 
									deviceInfo={deviceInfo}
									optionType={dialogueStepData.optionType}
									options={dialogueStepData.options} 
									selectOption={selectOption}
								/>
							</div>
						</div>
					}
				</div>
			</div>
			
			{/* Next dialogue step btn */}
			{(
				!isCompleted && 
				((dialogueStepData && dialogueStepData.nextStepId && !dialogueStepData.autoContinue) || nextStepId) 
			) && 
				<div className="Dialogue-nextBtn">
					<Button 
						classes={['next', 'animate']} 
						onClick={() => {
							goToNextDialogueStep(
								(nextStepId ? nextStepId : dialogueStepData.nextStepId), 
								dialogueArr, 
								points
							);
						}}
					/>
				</div>
			}
		</div>
	);
};

Dialogue.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	setShowPointsTrack: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool,
};

export default Dialogue;
