import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {loginUiTexts, generalUiTexts, gameUiTexts } from 'data/ui-texts';
import {modulesData} from 'data/modules-data';
import {checkIfModuleIsLocked} from 'helpers/game-helper';
import { renderMarkdown } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import Character from 'components/ui/character/character';
import './home.scss';

const Home = ({deviceInfo, userData, gameData, resetGameData, handleGoToPage, handleLogout}) => {

	return (
		<div className={'Home ' + deviceInfo.orientation}>
			<div className="Home-character">
				<Character page="home" characterId="manager-1" />		
			</div>
			<div className="Home-email"><span>{userData.email}</span></div>
			<div className="Home-logoutBtn">
				<Button classes={['logout']} text={loginUiTexts.logout} onClick={() => {handleLogout();}} />
			</div>
			<div className="Home-regionBtn" onClick={() => {handleGoToPage('region');}} />
			<div className="Home-highscoreBtn" onClick={() => {handleGoToPage('highscore');}} />
			<div className="Home-title">{renderMarkdown(generalUiTexts.gameTitle)}</div>
			<div className="Home-text"><span>{gameUiTexts.chooseGame}</span></div>
			<div className="Home-modules">
				{modulesData.map((moduleData) => {
					let isLocked = checkIfModuleIsLocked(moduleData, userData);
					if (gameData.isSwitchedOff === true) isLocked = true;
					return (
						<div 
							key={moduleData.id} 
							className={'Home-module' + (isLocked ? ' locked' : '')}
							onClick={() => {
								if (!isLocked || appConfig.env !== 'production') {
									handleGoToPage('module', moduleData.id);
								}
							}}
						>
							<div className="Home-moduleTitle"><span>{moduleData.title}</span></div>
							<div className="Home-moduleDescription"><span>{moduleData.description}</span></div>
						</div>
					);
				})}
			</div>

			{appConfig.env !== 'production' && <div className="Home-resetBtn">
				<Button classes={['white']} text={gameUiTexts.reset} onClick={() => {resetGameData();}} />
			</div>}
		</div>
	);
};

Home.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	resetGameData: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Home;
