import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {scrollTo} from 'helpers/scroll-helper';
import {sortPoints} from 'data/points-data';
import {shuffleArray} from 'helpers/array-helper';
import Progress from 'components/ui/progress/progress';
import TaskIntro from 'components/game/module/task-intro/task-intro';
import SortDndContainer from './sort-dnd-container';
import SortDndItem from './sort-dnd-item';
import SortDndPreview from './sort-dnd-preview';
import './sort.scss';

const Sort = (props) => {
	const {
		deviceInfo, 
		moduleData,
		playerTaskData, 
		taskData, 
		updateLoggedTime,
		handleInstantTaskEffects,
		handleCompleteTask,
		isFacilitator = false
	} = props;

	/* Get items to be sorted */
	const getItems = () => {
		/* Get items from player data or data file */
		let items = (playerTaskData && playerTaskData.sortedItems
			? playerTaskData.sortedItems // TODO: check it matches?
			: taskData.items.map((item) => {return {itemId: item.id, containerId: null};})
		);

		/* Shuffle items and return */
		return shuffleArray(items);
	};

	/* Track sorted items & errors */
	const [sortedItems, setSortedItems] = useState(getItems());
	const [errors, setErrors] = useState(0);

	/* Track scroll */
	const [canScrollDown, setCanScrollDown] = useState(false);

	/* Update sorted items if new task */
	useEffect(() => {
		const items = getItems();
		setSortedItems(items);
		setErrors(0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Find item
	 * @param {number} itemId 
	 * @returns 
	 */
	const handleFindItem = (itemId) => {
		const item = sortedItems.find((item) => {return item.itemId === itemId;});
		return (item ? item.containerId : null);
	};

	/**
	 * Move item to new container
	 * @param {number} itemId 
	 * @param {string} containerId 
	 */
	const handleMoveItem = (itemId, containerId) => {
		/* Update logged time */
		updateLoggedTime();
		
		/* Move item */
		let newSortedItems = JSON.parse(JSON.stringify(sortedItems));
		const itemIndex = newSortedItems.findIndex((item) => {return item.itemId === itemId;});
		newSortedItems[itemIndex].containerId = containerId;
		setSortedItems(newSortedItems);

		/* Check if it is an error */
		let newErrors = errors;
		const itemData = taskData.items.find((itemData) => {return itemData.id === itemId;});
		const isCorrect = (itemData.categoryIds.indexOf(containerId) >= 0);
		if (!isCorrect) {
			newErrors += 1;
			setErrors(newErrors);
		}

		/* Update streak */
		const instantEffects = [{type: 'streak', isCorrectAnswer: isCorrect}];

		/* Check if task is completed: all items are placed into a category && it is the right category */
		if (
			newSortedItems.every((item) => {return item.containerId !== null;}) && 
			newSortedItems.every((item) => {
				const itemData = taskData.items.find((itemData) => {return itemData.id === item.itemId;});
				return (itemData.categoryIds.indexOf(item.containerId) >= 0);
			})
		) {
			completeTask(newSortedItems, newErrors, instantEffects);
		} else {
			handleInstantTaskEffects(instantEffects);
		}
	};

	/**
	 * Complete task
	 */
	const completeTask = (newSortedItems, newErrors, instantEffects) => {
		/* Return if not all items have been sorted */
		if (newSortedItems.some((item) => {return item.containerId === null;})) return;

		/* Calculate points */
		let points = sortPoints.minPoints;
		let pointIndex = sortPoints.pointLimits.findIndex((limit) => {return newErrors <= limit;});
		if (pointIndex >= 0) points = sortPoints.pointValues[pointIndex];


		/* Save completed task */
		handleCompleteTask(
			'sort', 
			points, 
			newErrors, 
			instantEffects,
			{sortedItems: newSortedItems}
		);
	};

	/**
	 * Track scrolling
	 */
	const updateScrollDiff = () => {
		let newCanScrollDown = false;
		const containerRef = document.getElementById('sortContainer');
		if (containerRef) {
			const scrollDiff = containerRef.scrollHeight - containerRef.clientHeight;
			if (scrollDiff > 3 && (scrollDiff - containerRef.scrollTop > 3) ) newCanScrollDown = true;
		}
		setCanScrollDown(newCanScrollDown);
	};

	/**
	 * Smooth scroll to bottom
	 */
	 const scrollToBottom = () => {
		var div = document.getElementById('sortContainer');
		if (div) {
			scrollTo(div, (div.scrollHeight - div.clientHeight), 0.1, null);
			setCanScrollDown(false);
			// div.scrollTop = div.scrollHeight - div.clientHeight;
		}
	};

	/**
	 * Component mounted / will unmount
	 */
	 useEffect(() => {
		/* Component mounted */
		updateScrollDiff();
		

		return () => {
			/* Component will unmount */
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);




	return (
		<div className={'Sort ' + deviceInfo.orientation} >
			<Progress moduleId={moduleData.id} taskId={taskData.id} />
			<div id="sortContainer" className="Sort-content" onScroll={() => {updateScrollDiff();}}>
				<div className="Sort-intro">
					<TaskIntro 
						text={taskData.text}
						image={taskData.image}
					/>
				</div>
				<div className="Sort-categories">
					{taskData.categories && taskData.categories.map((categoryData) => {
						return (
							<div key={categoryData.id} className="Sort-category">
								{taskData.withHeader && <div className="Sort-categoryTitle">
									<span>{categoryData.text}</span>
								</div>}
								<SortDndContainer
									layout={taskData.layout}
									containerId={categoryData.id}
									handleFindItem={handleFindItem}
									handleMoveItem={handleMoveItem}
								>
									{sortedItems.filter((item) => {
										return item.containerId === categoryData.id;
									}).map((item) => {
										const itemData = taskData.items.find((itemData) => {
											return itemData.id === item.itemId;
										});
										if (!itemData) return null;

										let isDraggable = true;
										let classes = null;
										if (
											(playerTaskData && playerTaskData.isCompleted)
										) {
											isDraggable  = false;
											classes = ['completed'];
										} else {
											const isPlacedCorrect = 
												(itemData.categoryIds.indexOf(item.containerId) >= 0);
											classes = (isPlacedCorrect ? ['animateCorrect'] : ['animateWrong']);
											if (isPlacedCorrect) isDraggable = false;
										}
										return (
											<SortDndItem 
												key={itemData.id} 
												isDraggable={isDraggable} 
												classes={classes}
												itemId={itemData.id}
												isFacilitator={isFacilitator}
											>
												<span>{itemData.text}</span>
											</SortDndItem>
										);
									})}
								</SortDndContainer>
							</div>
						);
					})}
				</div>
				<div className="Sort-items">
					{sortedItems.filter((i) => {return i.containerId === null;}).map((item) => {
						const itemData = taskData.items.find((itemData) => {
							return itemData.id === item.itemId;
						});
						if (!itemData) return null;
						const isDraggable = (!playerTaskData || !playerTaskData.isCompleted) && !isFacilitator;
						return (
							<SortDndItem key={item.itemId} isDraggable={isDraggable} itemId={item.itemId}>
								<span>{itemData.text}</span>
							</SortDndItem>
						);
					})}
				</div>
				<SortDndPreview itemsData={taskData.items} />
				{canScrollDown && <div className="Sort-scrollDown" onClick={() => {scrollToBottom();}}/>}
			</div>
		</div>
	);
};

Sort.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool,
};

export default Sort;
