import {useState, useEffect} from 'react';
// import PropTypes from 'prop-types';
import firebaseInit from 'firebase-init';
import {getOrientation, getAspectRatio, checkIfInStandaloneMode} from 'helpers/device-helper';
import Auth from './auth/auth';
import ImageLoader from 'components/ui/image-loader/image-loader';
import './app.scss';

const App = () => {
	/* Device info */
	const [deviceInfo, setDeviceInfo] = useState(() => {
		return {
			isInStandaloneMode: checkIfInStandaloneMode(),
			isUsingTouch: false,
			orientation: getOrientation(),
			aspectRatio: getAspectRatio()
		};
	});

	/**
	 * User resized window, update orientation and aspect ratio
	 */
	const handleWindowResize = () => {
		let newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.orientation = getOrientation();
		newDeviceInfo.aspectRatio = getAspectRatio();
		setDeviceInfo(newDeviceInfo);
	};

	/**
	 * User interacted with a touch (first time only)
	 * We check for touch interaction, because we wnat to
	 * know what is used (not what is available).
	 */
	const handleTouchStart = () => {
		let newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.isUsingTouch = true;
		setDeviceInfo(newDeviceInfo);
		if (document.removeEventListener) window.removeEventListener('touchstart', handleTouchStart, false);
	};


	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Initialize firebase */
		firebaseInit();

		/* Add event listener for window size */
		if (document.addEventListener) window.addEventListener('resize', handleWindowResize, false);

		/* Add event listener for touch start */
		if (document.addEventListener) window.addEventListener('touchstart', handleTouchStart, false);
		
		/* Component will ummount */
		return () => {
			if (document.removeEventListener) window.removeEventListener('resize', handleWindowResize, false);
			if (document.removeEventListener) window.removeEventListener('touchstart', handleTouchStart, false);
		};
	});

	const scrollToTop = () => {
		const AppDiv = document.getElementById('App');
		if (AppDiv) AppDiv.scrollTop = 0;
	};

	/* Login and facilitator / game pages */
	return (
		<div id="App" className={'App ' + deviceInfo.orientation}>
			<Auth deviceInfo={deviceInfo} scrollToTop={scrollToTop} />
			<ImageLoader /> 
		</div>
	);
};



export default App;
