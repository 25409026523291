import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSprings, animated} from 'react-spring';
import {useDrag} from 'react-use-gesture';
import './carousel.scss';

const Carousel = ({deviceInfo, optionType, options, selectOption}) => {
	/* Layout */
	const [pixelsPerEm, setPixelsPerEm] = useState(0);
	const optionWidthEm = 14.25;
	const carouselWidthEm = 20.833;
	const optionDistanceEm = (carouselWidthEm - optionWidthEm) / 2.;
	
	/* Springs */
	 const [currentIndex, setCurrentIndex] = useState(0);
	const index = useRef(0);
	const [springProps, setSpringProps] = useSprings(options.length, (i) => {
		return {
			x: i * (optionWidthEm + (optionDistanceEm / 2.)) + optionDistanceEm,
			display: 'block',
			isSelected: (i === 0 ? true : false)
		};
	});

	/* Drag gesture */
	const bind = useDrag(({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
		const mxEm = (pixelsPerEm > 0 ? mx / pixelsPerEm : 0);
		const distanceEm = (pixelsPerEm > 0 ? distance / pixelsPerEm : 0);
		if (active && distanceEm > optionWidthEm / 2.) {
			let value = Math.min(index.current + (xDir > 0 ? -1 : 1), options.length - 1);
			value = Math.max(value, 0);
			setCurrentIndex(value);
			cancel(index.current = value);
		}

		setSpringProps((i) => {
			if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
			const x = (i - index.current) * (optionWidthEm + (optionDistanceEm / 2.)) 
				+ optionDistanceEm + (active ? mxEm : 0);
			return { x, display: 'block', isSelected: (i === index.current ? true : false) };
		});
	});

	/**
	 * Handle scroll
	 * @param {string} direction 
	 */
	 const handleScroll = (direction) => {
		let value = Math.min(currentIndex + (direction === 'left' ? -1 : 1), options.length - 1);
		setCurrentIndex(value);
		setSpringProps((i) => {
			if (i < value - 2 || i > currentIndex + 2) return { display: 'none' };
			const x = (i - value) * (optionWidthEm + (optionDistanceEm / 2.)) 
				+ optionDistanceEm;
			return { x, display: 'block', isSelected: (i === value ? true : false) };
		});
	};

	/* Component did mount */
	useEffect(() => {
		/* Get pixels per em */
		const carouselWidthPx = (document.getElementById('carousel') 
			? document.getElementById('carousel').offsetWidth 
			: 0
		);
		setPixelsPerEm(carouselWidthPx / carouselWidthEm);
		/* Reset carousel positions */
		// setSpringProps((i) => {
		// 	return {
		// 		x: i * (optionWidthEm + (optionDistanceEm / 2.)) + optionDistanceEm,
		// 		display: 'block',
		// 		isSelected: (i === 0 ? true : false)
		// 	};
		// });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	return (
		<div id="carousel" className="Carousel">
			{(deviceInfo.orientation === 'landscape' || deviceInfo.isUsingTouch === false) && <div 
				className={'Carousel-scrollLeftBtn' + (currentIndex > 0 ? ' active' : '')} 
				onClick={() => {handleScroll('left');}} 
			/>}	
			{(deviceInfo.orientation === 'landscape' || deviceInfo.isUsingTouch === false) && <div 
				className={'Carousel-scrollRightBtn' + (currentIndex < options.length - 1 ? ' active' : '')} 
				onClick={() => {handleScroll('right');}} 
			/>}
			<div id="options" className="Carousel-options">
				{springProps.map(({ x, display}, i) => {
					const optionData = options[i];
					return (
						<animated.div 
							{...bind()} 
							key={i} 
							style={{
								display, 
								transform: x.to((x) => {return `translate3d(${x}em,0,0)`;})
							}} 
							className={'Carousel-option ' + optionType}
						>
							<span>{optionData.text}</span>
							<div className="Carousel-selectBtn" onClick={() => {selectOption(optionData.id);}}/>
						</animated.div>
					);
				})
				}
			</div>
			{options.length > 1 && <div className="Carousel-navigation">
				{options.map((_, index) => {
					return (
						<div 
							key={index} 
							className={'Carousel-navigationDot' + (index === currentIndex ? ' selected' : '')}
						>
							<svg width="14" height="14" viewBox="0 0 14 14"><circle cx="7" cy="7" r="7"/></svg>
						</div>
					);
				})}
			</div>}
		</div>
	);
};

Carousel.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	optionType: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	selectOption: PropTypes.func.isRequired
};

export default Carousel;
