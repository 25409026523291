import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Character from 'components/ui/character/character';
import './instructions.scss';


const Instructions = ({deviceInfo, moduleData, taskData}) => {
	return (
		<div 
			className={'Instructions ' 
				+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')
				+ ' ' + (taskData.background ? taskData.background : moduleData.background)
				+ (taskData.animateCharacter ? ' animateCharacter-' + taskData.animationId : '')
			}
		>
			{(taskData.title || taskData.text) && 
				<div className={'Instructions-' + taskData.textType}>
					{taskData.title && <div className="Instructions-title">{taskData.title}</div>}
					{taskData.text && <div className="Instructions-text">{renderMarkdown(taskData.text)}</div>}
				</div>
			}
			{taskData.images.map((imageId) => {
				return (<div key={imageId} className={'Instructions-character ' + imageId}>
					<Character 
						page="instructions" 
						characterId={imageId}
					/>
				</div>);
			})}
			

		</div>
	);
};

Instructions.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired
};

export default Instructions;
