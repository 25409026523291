/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyBFN1bZ3OYmSaWFRfKRaqqKUEX-6uL5egU',
		authDomain: 'cgl-genmab-test.firebaseapp.com',
		projectId: 'cgl-genmab-test',
		storageBucket: 'cgl-genmab-test.appspot.com',
		messagingSenderId: '110565324716',
		appId: '1:110565324716:web:6f248cff00758ade9aac09'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyD8r1gBhhaGiRGl5MzeraFAHm1-oamvjtg',
		authDomain: 'cgl-genmab-production.firebaseapp.com',
		projectId: 'cgl-genmab-production',
		storageBucket: 'cgl-genmab-production.appspot.com',
		messagingSenderId: '579942077299',
		appId: '1:579942077299:web:e79460fd18bcc5706cab06'
	};
}


/* Initialize firebase */
firebase.initializeApp(firebaseConfig);


export default firebase.firestore;