import React from 'react';
import PropTypes from 'prop-types';
import {regionsData} from 'data/regions-data';
import {facilitatorUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import './facilitator-lottery-popup.scss';

const FacilitatorLotteryPopup = ({lotteryWinnerIds, regionId, players, setShowLotteryPopup}) => {

	/* Region data */
	const regionData = regionsData.find((r) => {return r.id === regionId;});

	let text = facilitatorUiTexts.lotteryPopup.drawingWinners;
	if (lotteryWinnerIds && lotteryWinnerIds.length > 0) {
		text = facilitatorUiTexts.lotteryPopup.theWinnersAre + ': ';
		lotteryWinnerIds.forEach((winnerId, index) => {
			const winnerData = players.find((p) => {return p.id === winnerId;});
			if (index > 0) {
				text += ', ';
			}
			if (winnerData) {
				text += winnerData.name;
			} else {
				text += '???';
			}
			if (index + 1 === lotteryWinnerIds.length) {
				text += '.';
			}
		});
	}

	return (
		<div className='FacilitatorLotteryPopup' onClick={(e) => {e.stopPropagation();}}>
			<div className='FacilitatorLotteryPopup-header'>
				<div className='FacilitatorLotteryPopup-title'>
					{facilitatorUiTexts.lotteryPopup.title} {regionData.name}
				</div>
				<div className='FacilitatorLotteryPopup-closeBtn' onClick={() => {setShowLotteryPopup(false);}}/>
			</div>
			<div className='FacilitatorLotteryPopup-body'>
				{renderMarkdown(text)}
			</div>
		</div>
	);

};

FacilitatorLotteryPopup.propTypes = {
	lotteryWinnerIds: PropTypes.array,
	regionId: PropTypes.string.isRequired,
	players: PropTypes.array.isRequired,
	setShowLotteryPopup: PropTypes.func.isRequired,
};

export default FacilitatorLotteryPopup;
