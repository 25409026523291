import PropTypes from 'prop-types';
import './character.scss';

const Character = ({page, characterId}) => {

	return (
		<div className={'Character ' + page + ' ' + characterId} />
	);
};

Character.propTypes = {
	page: PropTypes.string.isRequired,
	characterId: PropTypes.string.isRequired,
};

export default Character;
