import PropTypes from 'prop-types';
import './logo.scss';

const Logo = ({type = 'player'}) => {
	return (
		<div className={'Logo ' + type} />
	);
};

Logo.propTypes = {
	type: PropTypes.string,

};


export default Logo;
