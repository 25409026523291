import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {scrollTo} from 'helpers/scroll-helper';
import Progress from 'components/ui/progress/progress';
import BranchingStoryMc from './branching-story-mc';
import BranchingStoryInfo from './branching-story-info';
import Button from 'components/ui/button/button';
import './branching-story.scss';

const BranchingStory = (props) => {
	const {
		deviceInfo, 
		playerTaskData, 
		moduleData, 
		taskData,
		updateLoggedTime,
		handleCompleteTask,
		isFacilitator = false,
	} = props;

	let timeout = null;	

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track scroll */
	const [canScrollDown, setCanScrollDown] = useState(false);

	/**
	 * Get points
	 * @returns {number} points
	 */
	const getPoints = () => {
		let points = 0;
		if (isCompleted) points = playerTaskData.points;
		return points;
	};

	/**
	 * Prepare story
	 * @returns {array} storyArrTemp
	 */
	const prepareStory = () => {
		let storyArrTemp = [];

		if (playerTaskData && playerTaskData.storyArr && playerTaskData.storyArr.length > 0) {
			storyArrTemp = [...playerTaskData.storyArr];
		} else {
			const firstStoryStepData = (taskData && taskData.steps && taskData.steps.length > 0 
				? taskData.steps[0] : null);
	
			if (firstStoryStepData) {
				storyArrTemp.push({stepId: firstStoryStepData.id});
			}			
		}
		return storyArrTemp;
	};

	/* Track story progres, points */
	const [storyArr, setStoryArr] = useState(prepareStory());
	const [points, setPoints] = useState(getPoints());


	/**
	 * Complete a story step
	 * @param {number} newPoints 
	 * @param {string} nextStoryStepId 
	 * @param {number} optionId 
	 */
	const handleCompleteStoryStep = (newPoints, nextStoryStepId, optionId = null) => {
		/* Update logged time */
		updateLoggedTime();
		
		/* Update points */
		let totalPoints = points + newPoints;
		setPoints(totalPoints);

		/* Update step data */
		let newStoryArr = [...storyArr];
		newStoryArr[newStoryArr.length - 1].points = newPoints;
		newStoryArr[newStoryArr.length - 1].optionId = optionId;
		newStoryArr[newStoryArr.length - 1].isCompleted = true;

		if (nextStoryStepId) {			
			/* Continue to next step */
			newStoryArr.push({stepId: nextStoryStepId});

			/* Check if completion of next step is automatic (with a delay) */
			const nextStoryStepData = taskData.steps.find((s) => {return s.id === nextStoryStepId;});
			if (nextStoryStepData && nextStoryStepData.type === 'info') {
				if (nextStoryStepData.nextStepId) {
					if (nextStoryStepData.isAutoContinue) {
					/* Auto-nav to next story step */
						timeout = setTimeout(() => {
							handleCompleteStoryStep(
								(nextStoryStepData.points ? nextStoryStepData.points : 0), 
								nextStoryStepData.nextStepId
							);
						}, 500);
					}
				} else {
					/* Auto-complete task */
					timeout = setTimeout(() => {
						completeTask(
							newStoryArr, 
							totalPoints + (nextStoryStepData.points ? nextStoryStepData.points : 0)
						);
					}, 500);
				}
			}
			
		} else {
			/* No next step: Complete task */
			completeTask(newStoryArr, points + newPoints);
		}
		
		/* Update story */
		setStoryArr(newStoryArr);
	};

	/**
	 * Complete task
	 * @param {array} newSelectedOptionIds 
	 */
	const completeTask = (newStoryArr, newPoints) => {
		/* Errors */
		const errors = 0;

		/* Save completed task */
		handleCompleteTask(
			'branching-story', 
			newPoints, 
			errors, 
			[],
			{storyArr: newStoryArr}
		);
	};





	/**
	 * New branching story task, reset
	 */
	useEffect(() => {
		setPoints(getPoints());
		setStoryArr(prepareStory());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Track scrolling
	 */
	 const updateScrollDiff = () => {
		let newCanScrollDown = false;
		const containerRef = document.getElementById('story');
	
		if (containerRef) {
			const scrollDiff = containerRef.scrollHeight - containerRef.clientHeight;
			if (
				scrollDiff > 3 && 
				scrollDiff - containerRef.scrollTop > 3
			) newCanScrollDown = true;
		}
		setCanScrollDown(newCanScrollDown);
	};

	/**
	 * Smooth scroll to story bottom
	 */
	const scrollToBottom = () => {
		var div = document.getElementById('story');
		if (div) {
			scrollTo(div, (div.scrollHeight - div.clientHeight), 0.1, null);
			setCanScrollDown(false);
			// div.scrollTop = div.scrollHeight - div.clientHeight;
		}
	};

	/**
	 * Auto-scroll to bottom when story array is updated
	 */
	useEffect(() => {
		if (storyArr.length > 0) {
			
			updateScrollDiff();
		}
		if (storyArr.length > 1) scrollToBottom();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storyArr]);

	/**
	 * Component mounted / will unmount
	 */
	 useEffect(() => {
		/* Component mounted */


		return () => {
			/* Component will unmount */
			if (timeout) clearTimeout(timeout);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	/* Next story step button */
	let showNextStoryStepBtn = false;
	const storyStepData = (storyArr.length > 0 
		? taskData.steps.find((s) => {return s.id === storyArr[storyArr.length - 1].stepId;})
		: null
	);
	if (storyStepData && storyStepData.type === 'info' && storyStepData.nextStepId && !storyStepData.isAutoContinue) {
		showNextStoryStepBtn = true;
	}
	
	return (
		<div 
			className={'BranchingStory '
				+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')
				+ (taskData && taskData.background ? ' ' + taskData.background : '')}
		>
			<Progress moduleId={moduleData.id} taskId={taskData.id} />
			<div id="story" className="BranchingStory-story" onScroll={() => {updateScrollDiff();}}>
				{storyArr.map((storyStep, index) => {
					const storyStepData = taskData.steps.find((s) => {return s.id === storyStep.stepId;});	
					let StoryStepComponent = BranchingStoryInfo;
					if (
						storyStepData && 
						storyStepData.type === 'multiple-choice'
					) StoryStepComponent = BranchingStoryMc;
					
					let storyStepClass = 'BranchingStory-step' + (index > 0 && !isCompleted ? ' fadeIn' : '');
					let fadeLevel = null;
					if (index < storyArr.length - 1) {
						fadeLevel = (index < storyArr.length - 2 ? 'full' : 'half');
						storyStepClass += ' arrow ' + fadeLevel + 'FadeOut';
					}

					if (isFacilitator) storyStepClass += ' isFacilitator';

					return (
						<div key={index} className={storyStepClass}>
							<StoryStepComponent 
								fadeLevel={fadeLevel}
								playerStoryStepData = {storyStep}
								storyStepData={storyStepData} 
								moduleData={moduleData}
								handleCompleteStoryStep={handleCompleteStoryStep}
							/>		
						</div>
					);
				})}
			</div>

			{canScrollDown && <div className="BranchingStory-scrollDown" onClick={() => {scrollToBottom();}}/>}

			{/* Next story step button */}
			{showNextStoryStepBtn && <div className="BranchingStory-nextBtn">
				<Button 
					classes={['next', 'delay']} 
					onClick={() => {handleCompleteStoryStep(0, storyStepData.nextStepId);}} />
			</div>}			
		</div>
	);
};

BranchingStory.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	playerTaskData: PropTypes.object,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool,
};

export default BranchingStory;
