/* Effect */
const effectTemplate = {
	mainTemplate: {
		type: null, // feedback, popup, points, special-points, avatar-item
		conditions: null, // if no conditions, effect is always applied
		value: 0,
		feedback: null,
		popup: null,
		specialPoints: null,
		avatarItem: null
	},
	conditionTemplate: {
		// NB! This is a new feature, check that the game engine can handle it if you use it
		// currently works for: organize, spot-errors, paper doll, multiple choice (done effects only)
		type: 'no-errors' // no-errors
	},
	feedbackTemplate: {
		type: 'manager',
		text: '',
	},
	popupTemplate: {
		type: null, // new-badge, manager, clock-task-solved, spot-errors-result, spot-errors-fail, organize-result, try-again
		title: null,
		text: null
	},
	specialPointsTemplate: {
		id: null,
		pointId: null,
		value: 0
	},
	avatarItemTemplate: {
		categoryId: 'accessories', // can be any category from avatar-data.js
		itemId: null,
		text: null
	}
};



export {effectTemplate};
