const regionsData = [
	{
		id: 'denmark',
		name: 'Denmark' 
	},
	{
		id: 'netherlands',
		name: 'Netherlands'
	},
	{
		id: 'usa',
		name: 'USA'
	},
	{
		id: 'japan',
		name: 'Japan'
	}
];

export {
	regionsData
};
