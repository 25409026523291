/* Instructions */
const instructionsTemplate = {
	mainTemplate: {
		type: 'instructions',
		isSolveToContinue: false,
		showPoints: true,
		showAvatar: false,
		animateCharacter: false,
		animationId: 1,
		characterPose: 2,
		characterType: 'manager', // 'wizard', 'manager'
		background: null,
		title: null,
		text: null,
		textType: 'speech', // 'info', 'speech',
		images: ['manager-1']
	}
}

/* Image */
const imageTemplate = {
	mainTemplate: {
		type: 'image',
		isSolveToContinue: false,
		showPoints: true,
		image: null,
		text: null,
		nextBtnTypes: ['blue', 'done'],
		nextBtnText: 'Done'
	}
}

/* Multiple choice */
const multipleChoiceTemplate = {
	mainTemplate: {
		type: 'multiple-choice',
		isSolveToContinue: true,
		subtype: null, // null, dilemma, images
		showNumberOfAnswersToSelect: true, // set to false to hide the "choose x answers"
		numberOfAnswersToSelect: null, // used if subtype is 'dilemma'
		maxPoints: 0, // used if subtype is 'dilemma'
		layout: null, // used if subtype is 'image'
		shuffleOptions: true,
		showPoints: true,
		image: null,
		text: '',
		options: [],
		doneEffects: []
	},
	optionTemplate: {
		text: '', 
		isCorrect: false,
		effects: []
	}
};

/* Sort */
const sortTemplate = {
	mainTemplate: {
		type: 'sort',
		isSolveToContinue: true,
		showPoints: true,
		image: null,
		layout: '',
		text: '',
		withHeader: true,
		categories: [],
		items: []	
	},
	categoryTemplate: {
		text: ''
	},
	itemTemplate: {
		text: '',
		categoryIds: []
	}
};

/* Order */
const orderTemplate = {
	mainTemplate: {
		type: 'order',
		isSolveToContinue: true,
		showPoints: true,
		image: null,
		text: '',
		items: []
	},
	itemTemplate: {
		text: '',
		correctOrderNumbers: []
	}
};

/* Organize */
const organizeTemplate = {
	mainTemplate: {
		type: 'organize',
		isSolveToContinue: true,
		showPoints: true,
		showSolutionWhenDone: false,
		layout: '',
		title: null,
		text: '',
		items: [],
		rules: [],
		mainContainers: [],
		doneConditions: [],
		doneEffects: []
	},
	itemTemplate: {
		containerId: null, 
		image: null, 
		alt: '',
		correctContainerIds: []
	},
	mainContainerTemplate: {
		title: null,
		containers: []
	},
	containerTemplate: {
		type: '',
		finalItemId: null
	},
	doneConditionTemplate: {
		type: '', // empty-main-container
		mainContainerId: null
	},
	rulesTemplate: {
		type: '', // items-in-correct-containers
	}
};

/* Spot errors */
const spotErrorsTemplate = {
	mainTemplate: {
		type: 'spot-errors',
		isSolveToContinue: true,
		showPoints: true,
		timeLimit: null, // in seconds, null means no time limit
		doneWhenAllErrorsSpotted: false,
		showDefaultResultPopup: true,
		image: null,
		text: null,
		errors: [],
		doneEffects: []
	},
	errorTemplate: {
		effects: []
	}
};

/* Paper doll */
const paperDollTemplate = {
	mainTemplate: {
		type: 'paper-doll',
		isSolveToContinue: true,
		taskId: null, // e.g. 'deli-1-paper-doll-items'
		showPoints: true,
		showToolbox: true,
		items: [], // should be ordered after intended z-index (small to large)
		doneEffects: []
	},
	itemTemplate: {
		id: null,
		type: 'default', // default (selected image is same as in panel), alternative (suffix -b), bodytype (suffix f/m-b)
		isCorrect: false,
		correctConditions: null,
		dependsOnBodyHeight: false, // body types f and f2 are shorter
		dependsOnBodyWidth: false, // body types f2 and m2 are wider
		title: '',
		selectEffects: [],
		deselectEffects: [],
	},
	correctConditionTemplate: {
		type: 'avatar-category-in-use',
		categoryId: null
	}
};

/* Dialogue */
const dialogueTemplate = {
	mainTemplate: {
		type: 'dialogue',
		isSolveToContinue: true,
		showPoints: false,
		showPointsIfCompleted: true,
		person1: null,
		person2: null,
		maxPoints: 0,
		introText: '',
		introTextType: 'info', // info, speech
		introBackground: null,
		introCharacter: null,
		introShowAvatar: false,
		steps: []
	},
	stepTemplate: {
		showPoints: false,
		isFinalStep: false,
		imageFlip: false,
		type: 'dialogue', // dialog, action
		image: 'neutral', // neutral, happy, unhappy
		person: null,
		characters: null,
		items: null,
		text: null,
		autoContinue: false,
		nextStepId: null, // set if autoContinue is true
		optionType: 'dialogue', // dialogue, action
		optionPrompt: 'Choose your answer:', 
		options: [],
		effects: []
	},
	stepOptionTemplate: {
		text: '',
		points: 0,
		dialogueEffects: [],
		effects: [],
		autoContinue: false,
		nextStepId: null // id (continue) or null (stay at step)
	},
	stepOptionEffectTemplate: {
		type: 'dialogue', // dialogue, action
		text: ''
	}
};

/* Clock tasks */
const clockTasksTemplate = {
	mainTemplate: {
		type: 'clock-tasks',
		isSolveToContinue: true,
		layout: null, // unique for each clock task
		showPoints: true,
		startTime: {hours: 0, minutes: 0, period: 'a.m.'},
		maxDurationMinutes: 120,
		tasks: []
	},
	taskTemplate: {
		isRequired: true,
		isSolveToContinue: true,
		delayMinutes: 0,
		durationMinutes: 0,
		type: 'click-to-solve', // click-to-solve or any of the above
		hasImage: false, // has image that is not part of background
		hasSolvedImage: false, // has different image if solved
		maxPoints: 0,
		title: null,
		solvedEffects: [],
		failedEffects: []
	},
};

/* Branching story */
const branchingStoryTemplate = {
	mainTemplate: {
		type: 'branching-story',
		isSolveToContinue: true,
		background: null,
		showPoints: true,
		maxPoints: 0,
		steps: []
	},
	stepInfoTemplate: {
		type: 'info',
		isAutoContinue: true,
		nextStepId: null, // null if last step
		title: null,
		text: '',
		textType: 'info', // info, speech
		images: [],
	},
	stepMultipleChoiceTemplate: {
			type: 'multiple-choice',
			shuffleOptions: true,
			showNumberOfAnswersToSelect: true,
			title: null,
			text: '',
			image: null,
			options: []
	},
	stepMultipleChoiceOptionTemplate: {
		text: '', 
		effects: [],
		nextStepId: null // null (stay at story step), id (continue)
	}
};

export {
	instructionsTemplate,
	imageTemplate,
	multipleChoiceTemplate,
	sortTemplate,
	orderTemplate,
	organizeTemplate,
	spotErrorsTemplate,
	paperDollTemplate,
	dialogueTemplate,
	clockTasksTemplate,
	branchingStoryTemplate
};
