import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {errorUiTexts, loginUiTexts} from 'data/ui-texts';
import Loading from 'components/loading/loading';
import SelectSection from 'components/select-section/select-section';
import PlayerController from './player-controller';
import FacilitatorController from './facilitator-controller';

class UserController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			errMsg: null,
			authData: null,
			userData: null,
			gameData: null,
			section: null
		};
		this.unsubscribeUserData = null;
		this.unsubscribeGameData = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Get auth data */
		let authData = {
			id: this.props.authData.id ? this.props.authData.id : null,
			name: this.props.authData.name ? this.props.authData.name : null,
			email: this.props.authData.email ? this.props.authData.email.toLowerCase() : null
		};

		/* Check if user exists */
		if (authData && authData.id) {
			this.setState({authData}, () => {
				const db = firebase.firestore();
				db.collection(appConfig.usersDbName).doc(authData.id).get().then((doc) => {
					if (doc.exists) {
						/* User exists, subscribe to their data */
						this.subscribeToUserData().then((response) => {
							if (response.status === 'ok') {	
								/* Update last login */
								this.updateUser({
									lastLogin: dayjs(new Date()).format('YYYY-MM-DD'),
								}).catch((error) => {
									console.error(error);
								});
								/* Subscribe to game */
								this.subscribeToGameData().then((response) => {
									this.setState({isLoading: false});	
								});				
							} else {
								// TODO: probably connection error? show err msg?
								this.setState({isLoading: false});
							}
						});
					} else {
						/* User does not exist, create new user and subscribe to their data */
						this.createNewUser().then(() => {
							this.subscribeToUserData().then((response) => {
								if (response.status === 'ok') {
									/* Subscribe to game */
									this.subscribeToGameData().then((response) => {
										this.setState({isLoading: false});	
									});	
								} else {
									// TODO: probably connection error? show err msg?
									this.setState({isLoading: false});
								}
							});
						}).catch((err) => {
							// TODO
							console.error(err);
						});
					}
				});
			});
		} else {
			console.log('no auth data');
		}
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Cancel subscribtions */
		if (this.unsubscribeUserData !== null) this.unsubscribeUserData();
		if (this.unsubscribeGameData !== null) this.unsubscribeGameData();
	};

	/**
	 * Create new user
	 * @returns 
	 */
	createNewUser = () => {
		const userObj = {
			email: this.state.authData.email,
			name: this.state.authData.name,
			role: 'player',
			lastLogin: dayjs(new Date()).format('YYYY-MM-DD'),
			created: dayjs(new Date()).format('YYYY-MM-DD'),
		};
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(this.state.authData.id).set(userObj);
	};

	/**
	 * Subscribe to user data
	 * @returns {Promise}
	 */
	subscribeToUserData = () => {
		/* Cancel previous subscribtion */
		if (this.unsubscribeUserData !== null) this.unsubscribeUserData();

		/* Subscribe to user data */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeUserData = db.collection(appConfig.usersDbName).doc(this.state.authData.id)
				.onSnapshot((doc) => {
					if (doc.exists) {
						/* Save user data to state */
						const userData = {id: doc.id, ...doc.data()};
						this.setState({userData}, () => {resolve({status: 'ok'});});
					} else {
						/* User does not exist in database */
						resolve({status: 'ok'});
					}
				},
				(error) => {
					/* Error: Could not get user data */
					console.error('Could not get user data: ', error);
					resolve({status: 'error', error: error});
				});
		});
	};

	/**
	 * Subscribe to game data
	 * @returns {Promise}
	 */
	 subscribeToGameData = () => {
		/* Cancel previous subscribtion */
		if (this.unsubscribeGameData !== null) this.unsubscribeGameData();

		/* Subscribe to game data */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGameData = db.collection(appConfig.gamesDbName).doc('game-1').onSnapshot((doc) => {
				if (doc.exists) {
					/* Save game data to state */
					const newGameData = {id: doc.id, ...doc.data()};
					this.setState({ gameData: newGameData }, () => {resolve({status: 'ok'});});
				} else {
					/* Error: No game data */
					resolve({status: 'error', error: 'no-game-data'});
				}
			},
			(error) => {
				/* Error: Could not get game data */
				console.error('Could not get game data: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	/**
	 * Update user data
	 * @param {object} updates
	 * @returns {promise}
	 */
	 updateUser = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update player data */
		const userId = this.state.userData.id;
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(userId).update(updates);
	};

	/**
	 * Go to section (admin / facilitator / game)
	 * @param {string} section 
	 */
	handleGoToSection = (section) => {
		this.setState({section});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading type="loading-user-and-game-data" deviceInfo={this.props.deviceInfo} />
			);
		}

		/* Auth error */
		if (!this.state.userData || !this.state.gameData) {
			return (
				<div>
					<p>{errorUiTexts.unknownUserOrGame}</p>
					<button onClick={() => {this.props.handleLogout();}}>{loginUiTexts.logout}</button>
				</div>
			);
		}

		/* Component depends on user role */
		let Component = PlayerController;

		if (this.state.userData.role === 'facilitator') {
			/* Facilitator / admin can navigate to different sections */
			Component = SelectSection;
			if (this.state.section === 'game') Component = PlayerController;
			if (this.state.section === 'facilitator') Component = FacilitatorController;
		}
		
		return (
			<Component 
				deviceInfo={this.props.deviceInfo}	
				userData={this.state.userData}
				gameData={this.state.gameData}
				updateUser={this.updateUser}
				scrollToTop={this.props.scrollToTop}
				handleGoToSection={this.handleGoToSection}
				handleLogout={this.props.handleLogout} 
			/>
		);
	};
}

UserController.propTypes = {
	authData: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired
};

export default UserController;
