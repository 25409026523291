import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Button from '../button/button';
import './popup.scss';

const Popup = ({type, title, text, buttons, togglePopup}) => {


	return (
		<div className={'Popup' + (type ? ' ' + type : '')} onClick={() => {togglePopup();}}>
			<div 
				className="Popup-content" 
				onClick={(e) => {if (buttons && buttons.length > 0) e.stopPropagation();}}
			>
				<div className="Popup-contentWrap">
					{title && <div className="Popup-title">{title}</div>}
					{text && <div className="Popup-text">{renderMarkdown(text)}</div>}
					{buttons && <div className="Popup-buttons">
						{buttons.map((button, index) => {
							let buttonClasses = ['yellow', 'popup'];
							if (button.classes && button.classes.length > 0) {
								buttonClasses = buttonClasses.concat(button.classes);
							}
							return (
								<Button 
									key={index} 
									classes={buttonClasses}
									text={button.text}
									onClick={() => {button.action((button.parameters ? button.parameters : ''));}}
								/>
							);
						})}
					</div>}
				</div>
			</div>
		</div>
	);
};

Popup.propTypes = {
	type: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	buttons: PropTypes.array,
	togglePopup: PropTypes.func.isRequired
};

export default Popup;
