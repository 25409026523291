import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import './feedback.scss';


const Feedback = ({feedbackData, onClick}) => {
	let feedbackText = (feedbackData.text ? feedbackData.text : '');


	return (
		<div 
			className={'Feedback' + (feedbackData.type ? ' ' + feedbackData.type : '')}
			onClick={onClick}
		>
			<div className="Feedback-content">
				{renderMarkdown(feedbackText ? feedbackText : '')}
			</div>

		</div>
	);
};

Feedback.propTypes = {
	feedbackData: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default Feedback;
