let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let usersDbName = 'users';
let gamesDbName = 'games';
let gameUrl = 'localhost:3000';
let redirectUrl = 'http://localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let registrationUrl = null;
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-genmab-test.web.app';
	redirectUrl = 'https://cgl-genmab-test.web.app';
	apiURL = 'https://cgl-genmab-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-genmab-demo.web.app';
		redirectUrl = 'https://cgl-genmab-demo.web.app';
		apiURL = 'https://cgl-genmab-demo.web.app/api/';
		usersDbName = 'users-demo';
		gamesDbName = 'games-demo';
	}
}
if (env === 'production') {
	gameUrl = 'cgl-genmab-production.web.app';
	redirectUrl = 'https://cgl-genmab-production.web.app';
	apiURL = 'https://cgl-genmab-production.web.app/api/';
}

const appConfig = {
	env: env,
	apiURL: apiURL,
	gameUrl: gameUrl,
	redirectUrl: redirectUrl,
	registrationUrl: registrationUrl,
	usersDbName: usersDbName,
	gamesDbName: gamesDbName,
	// maxStarsPerModule: 3,
	inactivityLimitSeconds: 30,
	popupDelay: 500,
	feedbackDelay: 250,
	minNumberOfLotteryParticipants: 2
};

export default appConfig;