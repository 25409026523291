import PropTypes from 'prop-types';
import Button from 'components/ui/button/button';
import {renderMarkdown} from 'helpers/text-helper';
import {generalUiTexts} from 'data/ui-texts';
import Logo from 'components/ui/logo/logo';
import './intro.scss';

const Intro = ({deviceInfo, handleIntroSeen}) => {

	return (
		<div className={'Intro ' + deviceInfo.orientation}>
			<Logo />
			<div className="Intro-title">{renderMarkdown(generalUiTexts.gameTitle)}</div>
			<div className="Intro-text">{renderMarkdown(generalUiTexts.introText)}</div>
			<div className="Intro-startBtn">
				<Button 
					classes={['white']}
					text={generalUiTexts.start}
					onClick={() => {handleIntroSeen();}}
				/>
			</div>
		</div>
	);
};

Intro.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	handleIntroSeen: PropTypes.func.isRequired
};

export default Intro;
