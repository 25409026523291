import Instructions from 'components/game/module/instructions/instructions';
import BranchingStory from 'components/game/module/branching-story/branching-story';
import MultipleChoice from 'components/game/module/multiple-choice/multiple-choice';
import Order from 'components/game/module/order2/order2';
import Sort from 'components/game/module/sort/sort';
import Dialogue from 'components/game/module/dialogue/dialogue';

const moduleComponents = {
	'branching-story': BranchingStory,
	// 'clock-tasks': ClockTasks,
	dialogue: Dialogue,
	instructions: Instructions,
	// image: Image,
	'multiple-choice': MultipleChoice,
	order: Order,
	// organize: Organize,
	// 'paper-doll': PaperDoll,
	sort: Sort,
	// 'spot-errors': SpotErrors,
	// welcome: Welcome,
};

const modulesData = [
	{
		id: 'game-1',
		title: 'Round 1',
		unlockedByModuleIds: [],
		description: `All the basics about data breaches and what to do.`,
		tasks: require('data/module-tasks/game-1-tasks').moduleTasks,
	},
	{
		id: 'game-2',
		title: 'Round 2',
		unlockedByModuleIds: ['game-1'],
		description: `Get ready for the next level of data breach knowledge.`,
		tasks: require('data/module-tasks/game-2-tasks').moduleTasks,
	},
];

	export {
		moduleComponents,
		modulesData,
	};