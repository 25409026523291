import {
	starsPerPercentPoints, 
	multipleChoicePoints, 
	sortPoints, 
	orderPoints, 
	organizePoints,
	paperDollPoints} from 'data/points-data';
import {modulesData} from 'data/modules-data';

/**
 * Get error stats
 * @param {object} moduleData 
 * @param {object} playerModuleSession 
 * @returns 
 */
export function getErrorStats(moduleData, playerModuleSession) {
	let numberOfTasks = 0;
	let numberOfErrors = 0;
	let numberOfErrorFreeTasks = 0;

	moduleData.tasks.forEach((taskData) => {
		if (taskData.isSolveToContinue === false) return;
		const playerTaskData = (taskData.taskId && playerModuleSession && playerModuleSession.tasks 
			?	playerModuleSession.tasks.find((t) => {return t.taskId === taskData.taskId;})
			: null
		);
		if (playerTaskData) {
			numberOfTasks += 1;
			const errors = (playerTaskData.hasOwnProperty('errors') ? playerTaskData.errors : 0);
			if (errors > 0) {
				numberOfErrors += errors;
			} else {
				numberOfErrorFreeTasks += 1;
			}
		}
	});

	const avrNumberOfErrorsPerTask = (numberOfTasks > 0 
		? (numberOfErrors / numberOfTasks).toFixed(1)
		: '-'
	);

	return {
		numberOfTasks, avrNumberOfErrorsPerTask, numberOfErrorFreeTasks
	};
}

/**
 * Get max possible points of a module
 * @param {string} moduleId 
 * @returns 
 */
export function getModuleMaxPoints(moduleId) {
	let maxPoints = 0;

	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	if (moduleData && moduleData.tasks) {
		moduleData.tasks.forEach((task) => {
			maxPoints += getTaskMaxPoints(task);
		});
	}
	return maxPoints;
};

/**
 * Get max possible points of a task
 * @param {object} task 
 * @returns 
 */
export function getTaskMaxPoints(task) {
	let maxPoints = 0;

	/* Click-to-solve (only in clock-tasks) */
	if (task.type === 'click-to-solve' && task.maxPoints) maxPoints += task.maxPoints;

	/* Multiple choice */
	if (task.type === 'multiple-choice') {
		if (task.subtype === 'dilemma' && (task.maxPoints || task.maxPoints === 0)) {
			/* Subtype: dilemma */
			maxPoints += task.maxPoints;
		} else {
			/* Subtype: default or images */
			maxPoints += Math.min(task.options.length, multipleChoicePoints.basePoints);
		}
	}

	/* Sort, order, paper-doll */
	if (task.type === 'sort') maxPoints += sortPoints.pointValues[0];
	if (task.type === 'order') maxPoints += orderPoints.pointValues[0];
	if (task.type === 'paper-doll') maxPoints += paperDollPoints.pointValues[0];

	/* Spot-errors */
	if (task.type === 'spot-errors' && task.errors) maxPoints += task.errors.length;

	/* Dialogue, branching-story */
	if ((task.type === 'dialogue' || task.type === 'branching-story') && task.maxPoints) {
		maxPoints += task.maxPoints;
	}

	/* Organize */
	if (task.type === 'organize') {
		if (task.rules && task.rules.length > 0) {
			maxPoints += organizePoints.pointValues[0];
		} else {
			if (task.maxPoints && task.maxPoints > 0) {
				maxPoints += task.maxPoints;
			} else if (task.doneEffects && task.doneEffects.length > 0) {
				task.doneEffects.forEach((e) => {if (e.type === 'points' && e.vaue) maxPoints += e.value;});
			}
		}
	}

	/* Clock tasks */
	if (task.type === 'clock-tasks' && task.tasks.length > 0) {
		task.tasks.forEach((clockTask) => {
			maxPoints += getTaskMaxPoints(clockTask);
		});
	}
	
	return maxPoints;
}

/**
 * Get number of filled stars in a module
 * @param {number} points 
 * @param {number} maxPoints 
 * @returns 
 */
export function getNumberOfFilledStars(points, maxPoints) {
	if (!points || !maxPoints) return 0;

	const percentPoints = points / maxPoints * 100.;
	let numberOfFilledStars = 0;
	starsPerPercentPoints.pointSteps.forEach((step, index) => {
		if (percentPoints >= step) numberOfFilledStars = starsPerPercentPoints.filledStars[index];
	});

	return numberOfFilledStars;
};