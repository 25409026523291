import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import appConfig from 'config/app.config';
// import Loading from 'components/loading/loading';
import Game from 'components/game/game';

class PlayerController extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}



	/**
	 * Reset player data
	 */
	resetGameData = () => {
		this.props.updateUser({
			regionId: null,
			introSeen: false,
			modules: []
		}).then(() => {
			window.location.reload();
		});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		// if (this.state.isLoading) {
		// 	return (
		// 		<Loading type="" deviceInfo={this.props.deviceInfo} />
		// 	);
		// }

		/* Game */
		return (
			<Game 
				deviceInfo={this.props.deviceInfo}
				userData={this.props.userData}
				gameData={this.props.gameData}
				scrollToTop={this.props.scrollToTop}
				updateUser={this.props.updateUser}
				resetGameData={this.resetGameData}
				handleLogout={this.props.handleLogout}
			/>
		);
	};
}

PlayerController.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default PlayerController;
