

const errorUiTexts = {
	unknownError: 'Error: Unknown error.',
	invalidGameCode: 'Invalid game code.',
	unknownModuleId: 'Unknown module id',
	unknownModuleTaskId: 'Unknown module task id',
	unknownBadge: 'Unknown badge',
	unknownUserOrGame: 'Error: User data or game data not found.',
};

const loadingUiTexts = {
	loading: 'Loading',
	loadingUserAndGameData: 'Loading user data and game data',
	loadingFacilitatorData: 'Loading facilitator data',
	loadingModule: 'Loading game content'
};

const loginUiTexts = {
	login: 'Login',
	signUp: 'Sign up',
	logout: 'Log out',
	loggedInAs: 'Logged in as'
};

const generalUiTexts = {
	gameTitle: 'Personal<br />Data Breach Game',
	start: 'Start',
	selectLocation: 'Select office location',
	ok: 'OK',
	or: 'or',
	goToFacilitator: 'Continue to facilitator',
	goToGame: 'Play the game as employee',
	introText: `Personal Data Breach Game is a quick game about data breaches and data security. You earn points by answering questions about data breaches, and the fewer mistakes you make, the more points you get. In the end, one winner will be drawn per Genmab site (JP, US, DK, and NL), with each point giving you a lottery ticket.
		<br /><br />The game consists of two rounds. You can play one, take a break, and then play the other. Your score is based on your performance in both rounds combined. 
		<br /><br />If you don't like your score, you can play again to improve it.`
};

const gameUiTexts = {
	chooseGame: 'Choose game',
	reset: 'Reset',
	topPlayers: 'Top players',
	you: 'you',
	gameIsSwitchedOff: 'The game is switched off.',
	start: 'Start',
	choose: 'Choose',
	option: 'option',
	options: 'options',
	confirm: 'Confirm',
	done: 'Done',
	point: 'point',
	points: 'points',
	time: 'Time',
	finish: 'Finish',
	results: 'Results',
	stars: 'stars',
	timeSpent: 'Time spent',
	avrErrors: 'Average mistakes per task',
	noErrorsTasks: 'Number of tasks made without mistakes',
	goToHomePage: 'Go to home page',
	tryAgain: 'Try again',
	go: 'Go',
	ok: 'Ok',

	tryAgainPopup: {
		title: 'Try again?',
		text: 'Want to try to beat your own score? You can reset the module and play again.',
		resetBtn: 'Reset',
		cancelBtn: 'Cancel'
	},

	spotErrorsPopup: {
		title1: 'Oops!',
		text1: 'You only spotted ',
		title2: 'Not bad!',
		text2: 'You spotted ',
		title3: 'Well done!',
		text3: 'You spotted ',
		issue: ' issue.',
		issues: ' issues.'
	},

	orderPopup: {
		title: `You got %correctAnswers%/%totalAnswers%.`,
		text1: 'Try again and see if you can figure out what was wrong.',
		text2: `Try again. This time we'll show you which ones were correct.`,
		text3: `Try again!`
	},

	organizePopup: {
		text1: 'Perfect! You had 0 mistakes.',
		text2: 'Nice! You only had 1 mistake.',
		text3: 'You had %numberOfErrors% mistakes.',
		text4: 'Oops, you had %numberOfErrors% mistakes.'
	},

	paperDollPopup: {
		title: null,
		text1: 'You had them all right!',
		text2: 'You had %correctItems% right.<br /><br />You forgot: ',
		notNeeded: 'You did not need: '
	}
};

const facilitatorUiTexts = {
	all: 'All',
	on: 'On',
	off: 'Off',
	switchGameOn: 'Switch game on',
	switchGameOff: 'Switch game off',
	gameStatusDescription: 'When switched off, players cannot play the game.',
	region: 'Region',
	startedBy: 'Started by',
	completedBy: 'Completed by',
	avrTimeSpent: 'Average time spent',
	avrMistakes: 'Average mistakes',
	min: 'min',
	back: 'Back',
	task: 'Task',
	type: 'Type',
	perfectScores: 'Perfect scores',
	preview: 'Preview',
	name: 'Name',
	email: 'E-mail',
	region: 'Region',
	download: 'Download not completed',
	drawWinner: 'Draw winners',
	drawWinnerFrom: 'Draw winners from ',
	drawWinnerPopup: {
		title: 'Draw winners from ',
		info: `When you draw winners all participants will get a number of tickets equal to their total number of points.
			<br /><br />After the draw, the game progress of all participants will be reset.`,
		numberOfParticipants: 'Number of participants',
		totalNumberOfTickets: 'Total number of tickets',
		drawBtn: 'Draw winners',
		cancelBtn: 'Cancel',
		showPrevWinners: 'Show previous winners',
		tooFewParticipants: 'You cannot draw winners if the number of participants is less than',
		confirmDraw: 'Are you sure you want to draw winners? Remember that this will reset the game progress of all participants.'
	},
	lotteryPopup: {
		title: 'Draw 3 winners from ',
		drawingWinners: 'Drawing winners ...',
		theWinnersAre: 'The winners are',
	},
	prevWinnersPopup: {
		title: 'Previous winners from ',
	}

};



export {
	loadingUiTexts,
	errorUiTexts,
	gameUiTexts,
	generalUiTexts,
	loginUiTexts,
	facilitatorUiTexts,
};