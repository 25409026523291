import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {regionsData} from 'data/regions-data';
import {facilitatorUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './facilitator-draw-winner-popup.scss';

const FacilitatorDrawWinnerPopup = ({regionId, gameData, players, handleDrawWinner, setShowDrawWinnerPopup}) => {

	/* Region data */
	const regionData = regionsData.find((r) => {return r.id === regionId;});

	/* Players data */
	const participantsInRegion = players.filter((p) => {
		return (
			p.role === 'player' &&
			p.regionId === regionId &&
			p.modules &&
			p.modules.length > 0 &&
			p.modules.some((m) => {return (m.hasOwnProperty('maxPoints') && m.maxPoints > 0);})
		);
	});
	let numberOfTickets = 0;
	if (participantsInRegion.length > 0) {
		participantsInRegion.forEach((p) => {
			p.modules.forEach((m) => {
				if (m.hasOwnProperty('maxPoints') && m.maxPoints > 0) {
					numberOfTickets += m.maxPoints;
				}
			});
		});
	}

	/* Show previous winners */
	const [showPrevWinners, setShowPrevWinners] = useState(false);

	/* Previous winners */
	const previousWinners = (gameData.lotteryWinners && gameData.lotteryWinners[regionId]
		? gameData.lotteryWinners[regionId]
		: []
	);


	/* Error msg */
	const [errorMsg, setErrorMsg] = useState(null);

	/* Warning popup */
	const [showWarning, setShowWarning] = useState(false);

	/**
	 * Confirm draw winner
	 */
	const handleConfirmDrawWinner = () => {
		if (participantsInRegion.length < appConfig.minNumberOfLotteryParticipants) {
			/* Not enought participants */
			setErrorMsg(facilitatorUiTexts.drawWinnerPopup.tooFewParticipants 
				+ ' ' + appConfig.minNumberOfLotteryParticipants + '.');
		} else {
			/* Show warning */
			setShowWarning(true);
		}
	};

	if (showPrevWinners) {
		return (
			<div className='FacilitatorDrawWinnerPopup' onClick={(e) => {e.stopPropagation();}}>
				<div className='FacilitatorDrawWinnerPopup-header'>
					<div className='FacilitatorDrawWinnerPopup-title'>
						{facilitatorUiTexts.prevWinnersPopup.title} {regionData.name}
					</div>
					<div 
						className='FacilitatorDrawWinnerPopup-closeBtn' 
						onClick={() => {setShowPrevWinners(false);}}
					/>
				</div>
				<div className='FacilitatorDrawWinnerPopup-body'>
					{previousWinners.map((winners, index) => {
						let text = '';
						if (winners.hasOwnProperty('winnerIds')) {
							winners.winnerIds.forEach((winnerId, index) => {
								const winnerData = players.find((p) => {return p.id === winnerId;});
								if (index > 0) {
									text += ', ';
								}
								if (winnerData) {
									text += winnerData.name;
								} else {
									text += '???';
								}
							});
						}
						if (winners.hasOwnProperty('winnerId')) {
							const winnerData = players.find((p) => {return p.id === winners.winnerId;});
							text = (winnerData ? winnerData.name : '???');
						}
						
						return (
							<div key={index} className='FacilitatorDrawWinnerPopup-winner'>
								<span>{winners.drawDate}</span>
								<span>{text}</span>
							</div>
						);

					})}
				</div>
			</div>
		);
	}

	if (showWarning) {
		return (
			<div className='FacilitatorDrawWinnerPopup' onClick={(e) => {e.stopPropagation();}}>
				<div className='FacilitatorDrawWinnerPopup-header'>
					<div className='FacilitatorDrawWinnerPopup-title'>
						{facilitatorUiTexts.drawWinnerPopup.title} {regionData.name}
					</div>
					<div 
						className='FacilitatorDrawWinnerPopup-closeBtn' 
						onClick={() => {setShowDrawWinnerPopup(false);}}
					/>
				</div>
				<div className='FacilitatorDrawWinnerPopup-body'>
					<div className='FacilitatorDrawWinnerPopup-info'>
						{renderMarkdown(facilitatorUiTexts.drawWinnerPopup.confirmDraw)}
					</div>
					<div className='FacilitatorDrawWinnerPopup-buttons'>
						<Button 
							classes={['green']}
							text={facilitatorUiTexts.drawWinnerPopup.drawBtn}
							onClick={() => {handleDrawWinner(regionId);}}
						/>
						<Button 
							classes={['white', 'green-border']}
							text={facilitatorUiTexts.drawWinnerPopup.cancelBtn}
							onClick={() => {setShowWarning(false);}}
						/>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className='FacilitatorDrawWinnerPopup' onClick={(e) => {e.stopPropagation();}}>
			<div className='FacilitatorDrawWinnerPopup-header'>
				<div className='FacilitatorDrawWinnerPopup-title'>
					{facilitatorUiTexts.drawWinnerPopup.title} {regionData.name}
				</div>
				<div className='FacilitatorDrawWinnerPopup-closeBtn' onClick={() => {setShowDrawWinnerPopup(false);}}/>
			</div>
			<div className='FacilitatorDrawWinnerPopup-body'>
				<div className='FacilitatorDrawWinnerPopup-info'>
					{renderMarkdown(facilitatorUiTexts.drawWinnerPopup.info)}
				</div>
				<div className='FacilitatorDrawWinnerPopup-participants'>
					<span>{facilitatorUiTexts.drawWinnerPopup.numberOfParticipants}: </span>
					<span>{participantsInRegion.length}</span>

				</div>
				<div className='FacilitatorDrawWinnerPopup-tickets'>
					<span>{facilitatorUiTexts.drawWinnerPopup.totalNumberOfTickets}: </span>
					<span>{numberOfTickets}</span>
				</div>
				<div className='FacilitatorDrawWinnerPopup-buttons'>
					<Button 
						classes={['green']}
						text={facilitatorUiTexts.drawWinnerPopup.drawBtn}
						onClick={() => {handleConfirmDrawWinner();}}
					/>
					<Button 
						classes={['white', 'green-border']}
						text={facilitatorUiTexts.drawWinnerPopup.cancelBtn}
						onClick={() => {setShowDrawWinnerPopup(false);}}
					/>
				</div>
				<div className='FacilitatorDrawWinnerPopup-errorMsg'>
					<span>{errorMsg}</span>
				</div>
				{previousWinners.length > 0 && <div className='FacilitatorDrawWinnerPopup-prevWinners'>
					<span onClick={() => {setShowPrevWinners(true);}}>
						{facilitatorUiTexts.drawWinnerPopup.showPrevWinners}
					</span>
				</div>}
			</div>
		</div>
	);

};

FacilitatorDrawWinnerPopup.propTypes = {
	regionId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	players: PropTypes.array.isRequired,
	handleDrawWinner: PropTypes.func.isRequired,
	setShowDrawWinnerPopup: PropTypes.func.isRequired,
};

export default FacilitatorDrawWinnerPopup;
