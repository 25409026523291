const taskTemplates = require('./templates/task-templates');
const {effectTemplate} = require('./templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Welcome to the Personal Data Breach Game. I'm your Data Protection Officer, and I'll guide you through this game.`,
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `The game will present you with a series of tasks related to personal data breaches. The fewer mistakes you make, the more points you get.
			<br /><br />At the top of the screen, you can see how far you've progressed. You'll get your score at the end of the round.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Let's start with a simple task to set the scene.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-1-multiple-choice-personal-data-breach',
		image: 'folder-lock3',
		text: `Is it true that a "personal data breach" is defined as any breach of security leading to the destruction, loss, alteration, unauthorized disclosure to, or access to, personal data?`,
		numberOfAnswersToSelect: 1,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: `Yes, that's right`,
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Exactly!`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: `No, that's wrong`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Try again. I'm sure you'll get it this time. `
						})
					})
				]
			})
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Alright, let's get started. We'll begin with some general tasks and get more specific as you progress. 
			<br /><br />Good luck!`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-1-multiple-choice-highest-risk',
		image: 'folder-lock',
		text: `In which of these situations do you believe the risk of a personal data breach to be highest?`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: `When printing documents containing personal data`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `They all carry a potential risk for causing a personal data breach.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: `When using autofil to type email adresses in Outlook`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `They all carry a potential risk for causing a personal data breach.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: `When using real personal data while testing new IT systems`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `They all carry a potential risk for causing a personal data breach.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `When permissions to shared sites aren't kept updated`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `They all carry a potential risk for causing a personal data breach.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true, text: `All of the above`
			}),
		]
	}),

	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'game-1-order-data-breach-actions',
		image: 'folder-lock2',
		text: `You have just experienced something that could potentially be a personal data breach, what should you do? Place actions in the correct order with the first one on top. Click on two actions to make them switch places. Click "Done" when you are ready.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'limit', text: `If possible, limit the damage`, correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'notify', text: `Notify Genmab's DPO (Data Protection Officer) ASAP with the information you have`, correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'what-where-whom', text: 'Find out ALL details about the what, where, when, and who', correctOrderNumbers: [3]
			}),
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `After limiting further damage, you should always report all potential personal data breaches to the DPO (databreach&commat;genmab.com) immediately. The DPO will coordinate the handling of the breach from then on, including whether it needs to be reported to the individuals concerned and/or the authorities.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-1-multiple-choice-limit-damages',
		text: `After reporting a potential breach, what could be ways to limit the damage?`,
		image: 'folder-firstaid',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, text: `Restrict access to shared site if it has been set too broadly`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `It is always a good idea to review accesses at regular intervals.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Ask recipient to delete email they received by mistake`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Remember to also ask them to confirm in writing once they have deleted.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, text: `Forward leaked documents to legal department`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `This might cause further damage. Usually, the DPO only needs to know <strong>what</strong> was breached and not have access to the actual data.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, text: `Recall the email`, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `You can try, but this does not always work. Usually recalling an email piques people's curiosity and interest in reading it.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, text: `Put up a sign (paper or electronic) alerting users of a data breach`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `It's not necessary to create panic. Those that are affected will be informed, if deemed necessary.`
						})
					})
				]
			}),
		]
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-1-multiple-choice-email-wrong-address',
		subtype: 'images',
		image: 'email',
		text: `You have sent the following emails to the wrong recipient. Which one could be a potential data breach?`,
		layout: 'game-1-email-wrong-address',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `If this list included license plate or driver's information, it could potentially also be a personal data breach, likely a low risk one.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Not a personal breach, but of course not the appropriate use of company email nor a respectful way to talk about each other either.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, isCorrect: true}),
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Now, to show you some of the situations that can lead to potential data breaches, we want you to pretend to be "Benjamin", a new employee at Genmab.`,
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Benjamin is a very unlucky employee. You get to choose in which way he's unlucky, but also what he should do about it afterwards.`,
		textType: 'info',
		images: ['benjamin-1']
	}),

	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'game-1-branching-story-benjamin-resumes',
		maxPoints: 0,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-01',
				text: `Our employee has to read a lot of resumés for an open position while flying to a meeting. He wants to be able to read when he's offline, so what does he do? And remember, he's unlucky, so you might have to choose between options that aren't ideal.`,
				image: 'benjamin',
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: 'He downloads them to a USB stick', nextStepId: 'gb-02',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: 'He downloads them from the recruiting system and saves them on his laptop', nextStepId: 'gb-03',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: 'He brings printouts', nextStepId: 'gb-04',
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-02',
				text: `He reads a bit in a café before departure. But when he's about to board, he suddenly realizes he can't find the stick. What does he do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `Go back and try to find it`, nextStepId: 'gb-11'
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `Report it immediately as a potential personal data breach`, nextStepId: 'gb-12'
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: `Wait until he lands, then report it to the DPO`, nextStepId: 'gb-13'
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-03',
				text: `At the airport, he goes to the restroom. When he returns, his laptop has been stolen. He's almost a 100% certain that he locked it. His plane is leaving soon, what does he do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `It was locked, so no data breach. He still reports the theft when he lands`, nextStepId: 'gb-08'
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `Report it immediately as a potential personal data breach`, nextStepId: 'gb-09'
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: `Wait until he lands, then report it to the DPO`, nextStepId: 'gb-10'
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-04',
				text: `He reads a bit in a café while waiting. When at the gate, he notices that around half of the pages are missing. What does he do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `Go back and try to find them`, nextStepId: 'gb-05'
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `Report it immediately as a potential personal data breach`, nextStepId: 'gb-06'
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, text: `Wait until he lands, then report it to the DPO`, nextStepId: 'gb-07'
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-05',
				text: `He goes back, but can't find them. The waiter can't remember seeing the papers, but says that they might have been thrown out. 
					<br /><br />Benjamin's plane leaves very soon. What should he do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `Wait until he lands and then report it to the DPO`, nextStepId: 'gb-07'
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `Report it immediately as a potential personal data breach`, nextStepId: 'gb-14'
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-06',
				text: `He calls the DPO and reports the potential data breach. They ask him to go back and look for the papers, but they are gone. The DPO tells him to take his flight and then they will take it from here.`
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-07',
				text: `Benjamin has a busy day after the flight and forgets about reporting it. Unfortunately, it the papers were found by someone who reported it to the Data Protection Authority, and Genmab could be looking at a hefty fine.`
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-08',
				text: `Unfortunately the computer wasn't locked. The person who stole it later uses the information on the laptop for an elaborate phishing attack. Benjamin doesn't leave his laptop unattended in the future.`
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-09',
				text: `Better safe than sorry, and that's good since it turned out the laptop wasn't locked. But with help from IT, the DPO manages to minimize the damage, and Benjamin makes it to his meeting on time.`
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-10',
				text: `Benjamin has a busy day and forgets all about it. Unfortunately the computer wasn't locked. The person who stole it later uses the information on the laptop for some elaborate phishing attacks. Benjamin doesn't leave his laptop unattended in the future.`
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-11',
				text: `He goes back, but can't find the USB stick. The waiter can't remember seeing it.
					<br /><br />Benjamin's plane leaves very soon. What should he do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, text: `Wait until he lands and then report it to the DPO`, nextStepId: 'gb-13'
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, text: `Report it immediately as a potential data breach`, nextStepId: 'gb-14'
					})
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-12',
				text: `He calls the DPO and reports the potential data breach. They ask him to go back and look for the USB stick, but it is gone. The DPO tells him to take his flight and then they will take it from here.`
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-13',
				text: `Benjamin has a busy day after the flight and forgets about reporting it. Unfortunately, the USB stick was found by someone who reported it to the Data Protection Authorities, and Genmab could be looking at a hefty fine.`
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-14',
				text: `Benjamin calls the DPO who takes it from there. He barely makes his flight and worries all day about the outcome of the breach.`
			})
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `As you can see, many situations can lead to potential personal data breaches. The important thing is to be aware at all times. Should anything happen, try to limit further damage (stop the leak!), contact the DPO and wait for further instructions.`,
		images: ['manager-2']
	}),	

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Now, let's look at some more examples of personal data breaches.`,
		images: ['manager-2']
	}),	

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-1-multiple-choice-study-data-lost',
		image: 'box',
		text: `Clinical data from a study (including personally identifiable information) is sent to an external archive via a courier. The box is lost in the mail. 
			<br /><br />Should this be reported to the DPO as a potential personal data breach?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: `I informed the courier company they should report it to Genmab's DPO`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `That's not the best way to go, as it will delay the process. If you hear about a potential breach, contact the DPO asap.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: `Wait a week before reporting. Sometimes things show up again after some time`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Always report ASAP! We don't know what has happened, and if it's a serious breach the timelines are very short.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: `No, the boxes only contain patient (pseudonymous) data, so no individuals can be identified by name`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Pseudonymous data is also considered personal data and therefore also subject to data protection laws. `
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				isCorrect: true,
				text: `Yes, report it immediately.`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Exactly! Pseudonymous data is still considered personal data and is subject to data protection regulations.`
						})
					})
				]
			}),		
		]
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-1-multiple-choice-smart-function',
		image: 'button2',
		text: `An employee is working on implementing a new HR system and has to test a new smart feature. What is particularly important to consider when it comes to personal data protection?`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: `Use dummy data for the test instead of real personal data`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: `If you have to use real personal data, limit access and delete after use`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: `Limit access to only those needing to perform the test`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Remove access after the test or review at regular intervals`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: `Only collect the personal data needed for testing`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				isCorrect: true,
				text: `All of the things above should be considered`
			}),
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Now, let's try and expand on that story a bit. 
			<br /><br />The employee has to use real personal data of Genmab employees for the HR system test, and he forgets about it after testing. What could possibly go wrong?`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-1-multiple-choice-forgotten-data',
		image: 'monitor',
		text: `A couple of years later the employee is contacted by a colleague in IT (Ahmed). Ahmed says he can see data (financial info, address, phone number, etc.) about approx. 500 current and previous employees in the "forgotten" test environment.
			<br /><br />What should the employee do?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: `Tell Ahmed not to worry and simply delete the data`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: `Send an email to the 500 employees and tell them about this issue`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `You should only think about reporting to the DPO. The DPO will handle all communications to the individuals and the authorities, as relevant.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				isCorrect: true,
				text: `Call the DPO and report the potential breach`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				isCorrect: true,
				text: `Immediately restrict access to the site`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: `Get the full picture of the who, how long, and when before reporting it to the DPO`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, 
				text: `Print all the data to have a hard copy for the DPO`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `No need to do this. As a rule, the DPO doesn't need to see the exact data that was breached (that could be an additional breach!), but rather an explanation of what was breached.`
						})
					})
				]
			}),
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `When it comes to personal data breaches, we always have to work with worst-case scenarios. Let's take a look at a couple of those situations.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Mark has been away on a long sick-leave. His manager, Line, is working on a tailored program for him to slowly get back to work. She has a long train commute and prints all correspondence with Mark, his doctor and HR to work on his "back to work" program.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-1-multiple-choice-papers-left-on-train',
		image: 'documents',
		text: `Now, let's assume she accidentally leaves the documents in the train. What could go wrong?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				isCorrect: true,
				text: `They are found by a journalist, who thinks this is a good story`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `This could lead to serious reputational and financial damage for Genmab.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				isCorrect: true,
				text: `This could affect Genmab's reputation as a good place to work`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Yes. We don't want potential applicants to think that we as a company can't handle their data securely.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				isCorrect: true,
				text: `They could be used for a phishing attack targetting Mark or others`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Maybe a "local union representative" calls Mark and offers him "help" knowing that he's in a vulnerable position.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `They could be found by a competitor looking to do industrial spionage`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `This could be an issue in the case of confidential information or trade secrets. Probably not the case with Mark's personal data.`
						})
					})
				]
			})
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Some of these situations might seem unlikely, but they can happen. And in the end, we are responsible for the personal data individuals (employees, HCPs, patients) entrust us with.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'game-1-order-papers-left-on-train',
		image: 'documents2',
		text: `What should Line do when she realizes she has forgotten the papers on the train? Place the actions in the correct order. Click on two actions to make them swap places.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'call', text: `Try to contain the damage (e.g. call the train company to see if they've located the documents)`, correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'report', text: 'Report to the DPO', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'wait', text: 'Wait until the train company calls back with an answer', correctOrderNumbers: [3]
			}),
		]
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-1-multiple-choice-found-report',
		image: 'documents',
		text: `If Line had forgotten a laptop instead of printed documents, should she still report it to the DPO?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				isCorrect: true,
				text: `No, not if it was locked`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `All Genmab-issued phones and computers are encrypted, so if they are locked, there's no way to access the data. You should still report the theft to IT through Service Desk.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, text: `Yes, always`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Not always. If she's sure the laptop was locked, then it is not necessary to report it to the DPO. She still needs to report the theft to IT through Service Desk.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {id: 3, text: `It depends on the type of data on the computer`}),
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Alright, that's about it for this first round of the game. Just one more quick question.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-1-multiple-choice-take-away',
		text: `If you should take away one main learning from this game so far, what would this be?`,
		image: 'folder-lock',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: `I can prevent all personal data breaches by being careful`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Being careful is great, but you simply can't foresee everything. Try another.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				isCorrect: true,
				text: `Always report all potential personal data breaches to the DPO immediately`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Exactly! Remember this email: databreach&commat;genmab.com.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: `Personal data breaches aren't a big issue for Genmab`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Personal data breaches have the potential of being BIG issues both for companies, and for the individuals whose data is breached.`
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Never print documents containing personal data`,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: `Working online is better. But if you need to print documents, just ensure you keep them safe and dispose of them securely.`
						})
					})
				]
			}),
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Well done, you've now completed the first round of this game. This unlocks the second round which you can access through the home screen. 
			<br /><br />Remember you can also try this game again and see if you can improve your score. `,
		images: ['manager-2']
	}),
]

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'game-1-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export {
	moduleTasks
};