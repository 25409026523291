import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {scrollTo} from 'helpers/scroll-helper';
import {orderPoints} from 'data/points-data';
import {shuffleArray} from 'helpers/array-helper';
import {gameUiTexts} from 'data/ui-texts';
import { useSprings, animated, interpolate } from 'react-spring';

import Progress from 'components/ui/progress/progress';
import Button from 'components/ui/button/button';
import TaskIntro from 'components/game/module/task-intro/task-intro';
import Popup from 'components/ui/popup/popup';
import './order2.scss';

const Order2 = (props) => {
	const {
		deviceInfo, 
		playerTaskData, 
		moduleData,
		taskData,
		updateLoggedTime,
		handleInstantTaskEffects,
		handleCompleteTask,
		isFacilitator = false
	} = props;

	/* Timeout */
	let timeout = useRef(null);

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track scroll */
	const [canScrollDown, setCanScrollDown] = useState(false);

	/* Track ordered item ids (current and prev round) and number of rounds */
	const [orderedItems, setOrderedItems] = useState([]);
	const [roundNumber, setRoundNumber] = useState(1);

	/* Springs (item positions) */
	const positions = useRef(taskData.items.map((item) => {return {id: item.id, posY: 0};}));
	const setPositions = (positions) => {
		return (index) => {
			const itemId = positions[index].id;
			const posY = (document.getElementById(itemId) 
				? document.getElementById(itemId).offsetTop
				: 0
			);
			return {posY: posY};

		};
	};
	const [springs, setSprings] = useSprings(taskData.items.length, setPositions(positions.current));

	/* Get items to be ordered */
	const getOrderedItems = () => {
		/* Get items from player data or data file */
		let items = (playerTaskData && playerTaskData.orderedItems
			? playerTaskData.orderedItems // TODO: check it matches?
			: shuffleArray(taskData.items.map((item) => {return {id: item.id, isSelected: false, isLocked: false};}))
		);
		return items;
	};



	/* Popup with number of correct positions */
	const [popupData, setPopupData] = useState(null);

	/* Update sorted items if new task */
	useEffect(() => {
		setOrderedItems(getOrderedItems());
		setRoundNumber(1);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);


	/**
	 * Select item
	 * @param {string} itemId 
	 * @returns 
	 */
	const handleSelectItem = (itemId) => {
		/* Update logged time */
		updateLoggedTime();

		let newOrderedItems = JSON.parse(JSON.stringify(orderedItems));
		const selectedItems = newOrderedItems.filter((i) => {return i.isSelected === true;});
		
		/* Switch already in progress, ignore 3rd select */
		if (selectedItems.length > 1) return;

		/* Get clicked item index */
		const itemIndex = newOrderedItems.findIndex((i) => {return i.id === itemId;});
		if (itemIndex >= 0) {
			/* Ignore solved (locked) items */
			if (newOrderedItems[itemIndex].isLocked === true) return;

			newOrderedItems[itemIndex].isSelected = !newOrderedItems[itemIndex].isSelected;
			setOrderedItems(newOrderedItems);
		}
	};

	/* Ordered items updated, either switch 2 items or update item positions */
	useEffect(() => {
		let newOrderedItems = JSON.parse(JSON.stringify(orderedItems));
		const selectedItems = newOrderedItems.filter((i) => {return i.isSelected === true;});

		/* Switch items */
		if (selectedItems.length === 2) {
			const itemIndex0 = newOrderedItems.findIndex((i) => {return i.id === selectedItems[0].id;});
			const itemIndex1 = newOrderedItems.findIndex((i) => {return i.id === selectedItems[1].id;});
			newOrderedItems[itemIndex0] = selectedItems[1];
			newOrderedItems[itemIndex0].isSelected = false;
			newOrderedItems[itemIndex1] = selectedItems[0];
			newOrderedItems[itemIndex1].isSelected = false;

			timeout.current = setTimeout(() => {setOrderedItems(newOrderedItems);}, 500);
		}

		/* Update positions */
		if (selectedItems.length === 0) {
			setSprings(setPositions(positions.current));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderedItems]);

	/**
	 * Confirm order
	 */
	const confirmOrder = () => {
		/* Count errors */
		let errors = 0;
		orderedItems.forEach((item, index) => {
			const itemData = taskData.items.find((i) => {return i.id === item.id;});
			if (itemData && itemData.correctOrderNumbers && itemData.correctOrderNumbers.indexOf(index + 1) < 0) {
				errors += 1;
			}
		});

		if (errors > 0) {
			/* Update streak */
			const instantEffects = [{type: 'streak', isCorrectAnswer: false}];
			handleInstantTaskEffects(instantEffects);

			/* Show popup */
			const popupTextNumber = (roundNumber < 3 ? roundNumber : 3);
			const popupText = gameUiTexts.orderPopup['text' + popupTextNumber];
			const popupTitle = gameUiTexts.orderPopup.title
				.replace('%correctAnswers%', (orderedItems.length - errors))
				.replace('%totalAnswers%', orderedItems.length);
			setPopupData({
				title: popupTitle,
				text: popupText
			});
		} else {
			/* Complete task */
			completeTask();
		}
	};

	/**
	 * Close popup
	 */
	const handleClosePopup = () => {
		/* Update round number */
		const newRoundNumber = roundNumber + 1;
		setRoundNumber(newRoundNumber);

		/* Lock correctly placed items if round >= 3 */
		if (newRoundNumber >= 3) {
			let newOrderedItemIds = JSON.parse(JSON.stringify(orderedItems));
			newOrderedItemIds.forEach((item, index) => {
				const itemData = taskData.items.find((i) => {return i.id === item.id;});
				if (itemData && itemData.correctOrderNumbers && itemData.correctOrderNumbers.indexOf(index + 1) >= 0) {
					item.isLocked = true;
				}
			});
			setOrderedItems(newOrderedItemIds);
		}

		/* Close popup */
		setPopupData(null);
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {
		/* Errors */ 
		const errors = (roundNumber - 1);
		
		/* Calculate points */
		let points = orderPoints.minPoints;
		let pointIndex = orderPoints.pointLimits.findIndex((limit) => {return errors <= limit;});
		if (pointIndex >= 0) points = orderPoints.pointValues[pointIndex];

		/* Save completed task */
		handleCompleteTask(
			'order', 
			points, 
			errors, 
			[{type: 'streak', isCorrectAnswer: true}],
			{orderedItems: orderedItems}
		);
	};

	/**
	 * Track scrolling
	 */
	const updateScrollDiff = () => {
		let newCanScrollDown = false;
		const containerRef = document.getElementById('orderContainer');
		if (containerRef) {
			const scrollDiff = containerRef.scrollHeight - containerRef.clientHeight;
			if (scrollDiff > 3 && (scrollDiff - containerRef.scrollTop > 3) ) newCanScrollDown = true;
		}
		setCanScrollDown(newCanScrollDown);
	};

	/**
 * Smooth scroll to bottom
 */
	const scrollToBottom = () => {
		var div = document.getElementById('orderContainer');
		if (div) {
			scrollTo(div, (div.scrollHeight - div.clientHeight), 0.1, null);
			setCanScrollDown(false);
		// div.scrollTop = div.scrollHeight - div.clientHeight;
		}
	};

	/**
 * Component mounted / will unmount
 */
	useEffect(() => {
	/* Component mounted */
		updateScrollDiff();
	

		return () => {
		/* Component will unmount */
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	

	return (
		<div className={'Order2 ' + (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')}>
			<Progress moduleId={moduleData.id} taskId={taskData.id} />
			<div id="orderContainer" className="Order2-content" onScroll={() => {updateScrollDiff();}}>
				<div id="taskIntro" className="Order2-intro">
					<TaskIntro 
						text={taskData.text}
						image={taskData.image}
					/>
				</div>
				<div id="orderItems" className="Order2-hiddenItems" >
					{orderedItems.map((item, index) => {					
						const itemData = taskData.items.find((i) => {return i.id === item.id;});
						if (!itemData) return null;
						return (
							<div 
								key={index} 
								id={item.id}
								className="Order2-hiddenItem"
							>
								{itemData.text}
							</div>
						);
					})}
					<div className="Order2-items">
						{springs.map(({posY}, index) => {
							const itemId = taskData.items[index].id;
							const itemData = orderedItems.find((i) => {return i.id === itemId;});
							
							if (!itemData || posY === null) return null;
							let isSelectable = true;
							let itemClass = 'Order2-item' + (itemData.isSelected === true ? ' selected' : '');
							const isPlacedCorrect = taskData.items[index].correctOrderNumbers.indexOf(index + 1) >= 0;
							if (isCompleted || (itemData.isLocked && isPlacedCorrect)) {
								isSelectable  = false;
								itemClass += ' correct';
							}
							if (isFacilitator) {
								isSelectable = false;
							}
							return (
								<animated.div
									key={index} 
									style={{
										transform: interpolate([posY], (y) => {return `translate3d(0,${y}px,0)`;})
									}}
									className={itemClass + (isSelectable ? '' : ' locked')}
									onClick={() => {if (isSelectable) handleSelectItem(itemId);}}
								>
									{taskData.items[index].text}
								</animated.div>
							);
						})}
					</div>
				</div>
			</div>
			
			{canScrollDown && <div className="Order2-scrollDown" onClick={() => {scrollToBottom();}}/>}
			{/* Done button */}
			{!isCompleted && !isFacilitator && <div className="Order2-doneBtn">
				<Button
					classes={['white']}
					text={gameUiTexts.done}
					onClick={confirmOrder}
				/>
			</div>}

			{popupData && <Popup 
				type="order-result"
				title={popupData.title}
				text={popupData.text}
				togglePopup={handleClosePopup}
			/>}
		</div>
	);
};

Order2.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	playerTaskData: PropTypes.object,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool,
};

export default Order2;
