import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import {facilitatorUiTexts} from 'data/ui-texts';
import {modulesData} from 'data/modules-data';
import {regionsData} from 'data/regions-data';
import FacilitatorHeader from './facilitator-header/facilitator-header';
import FacilitatorOverviewStats from './facilitator-overview-stats/facilitator-overview-stats';
import FacilitatorRegionStats from './facilitator-region-stats/facilitator-region-stats';
import FacilitatorTaskPopup from './facilitator-task-popup/facilitator-task-popup';
import FacilitatorGameStatus from './facilitator-game-status/facilitator-game-status';
import FacilitatorGameWinner from './facilitator-game-winner/facilitator-game-winner';
import FacilitatorDrawWinnerPopup from './facilitator-draw-winner-popup/facilitator-draw-winner-popup';
import FacilitatorLotteryPopup from './facilitator-lottery-popup/facilitator-lottery-popup';
import './facilitator.scss';

const Facilitator = (props) => {
	const {
		deviceInfo,
		userData, 
		gameData, 
		players,
		updateGameData,
		drawWinnersInRegion,
		handleLogout,
	} = props;

	/* Regions list */
	const regionsList = [...regionsData];
	regionsList.push({id: 'all', name: facilitatorUiTexts.all});

	/* Selected module / region id */
	const [moduleId, setModuleId] = useState(modulesData[0].id);
	const [regionId, setRegionId] = useState(null);
	const [taskId, setTaskId] = useState(null);

	/* Get content component */
	let PageComponent = FacilitatorOverviewStats;
	if (regionId) PageComponent = FacilitatorRegionStats;

	/* Draw(ing) winner popup */
	const [showDrawWinnerPopup, setShowDrawWinnerPopup] = useState(false);
	const [showLotteryPopup, setShowLotteryPopup] = useState(false);
	const [lotteryWinnerIds, setLotteryWinnerIds] = useState(null);

	/**
	 * Draw winner of lottery
	 */
	const handleDrawWinner = () => {
		setLotteryWinnerIds(null);
		if (regionId && showDrawWinnerPopup) {
			setShowDrawWinnerPopup(false);
			setShowLotteryPopup(true);
			drawWinnersInRegion(regionId).then((response) => {
				setLotteryWinnerIds(response.winnerIds);
			});
		}
	};

	return (
		<div className="Facilitator">
			<DndProvider options={HTML5toTouch}>
				<div className="Facilitator-header">
					{/* Header */}
					<FacilitatorHeader 
						userData={userData} 
						handleLogout={handleLogout}
					/>
				</div>
				<div className="Facilitator-content">
					{/* Game status */}
					{!regionId && <FacilitatorGameStatus gameData={gameData} updateGameData={updateGameData} />}
					
					{/* Region winner */}
					{(regionId && regionId !== 'all') && 
						<FacilitatorGameWinner 
							regionId={regionId} 
							setShowDrawWinnerPopup={setShowDrawWinnerPopup}
						/>}

					{/* Modules */}
					<div className="Facilitator-modules">
						{modulesData.map((module) => {
							return (
								<div 
									key={module.id} 
									className={'Facilitator-module' 
										+ (module.id === moduleId ? ' selected' : '')}
									onClick={() => {setModuleId(module.id);}}
								>
									<span>{module.title}</span>
								</div>
							);
						})}
					</div>

					{/* Back button & region name*/}
					{regionId && <div className="Facilitator-region" onClick={() => {setRegionId(null);}}>
						<div className="Facilitator-backBtn" />
						<div className="Facilitator-regionName">
							<span>{regionsList.find((r) => {return r.id === regionId;}).name}</span>
						</div>
					</div>}

					{/* Stats (overview or region) */}
					<PageComponent 
						moduleId={moduleId}
						regionId={regionId}
						deviceInfo={deviceInfo}
						regionsList={regionsList}
						gameData={gameData}
						players={players}
						updateGameData={updateGameData}
						setRegionId={setRegionId}
						setTaskId={setTaskId}
					/>
				</div>

				{/* Task popup */}
				{(regionId && moduleId && taskId) &&
					<div className='Facilitator-popupOverlay' onClick={() => {setTaskId(null);}}>
						<FacilitatorTaskPopup 
							regionId={regionId}
							moduleId={moduleId}
							taskId={taskId}
							deviceInfo={deviceInfo}
							players={players}
							setTaskId={setTaskId}
							closePopup={() => {setTaskId(null);}}
						/>
					</div>}

				{(showDrawWinnerPopup && regionId) &&
					<div className='Facilitator-popupOverlay' onClick={() => {setShowDrawWinnerPopup(false);}}>
						<FacilitatorDrawWinnerPopup 
							regionId={regionId}
							gameData={gameData}
							players={players}
							handleDrawWinner={handleDrawWinner}
							setShowDrawWinnerPopup={setShowDrawWinnerPopup}
						/>
					</div>
				}
				{(showLotteryPopup && regionId) &&
					<div className='Facilitator-popupOverlay'>
						<FacilitatorLotteryPopup 
							lotteryWinnerIds={lotteryWinnerIds}
							regionId={regionId}
							players={players}
							gameData={gameData}
							setShowLotteryPopup={setShowLotteryPopup}
						/>
					</div>
				}
			</DndProvider>
		</div>
	);
};

Facilitator.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object,
	players: PropTypes.array.isRequired,
	updateGameData: PropTypes.func.isRequired,
	drawWinnersInRegion: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Facilitator;
