const taskTemplates = require('./templates/task-templates');
const {effectTemplate} = require('./templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Welcome to Round 2 of the Personal Data Breach Game. I hope you're ready for a challenge.`,
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `You have already learnt quite a bit about personal data breaches, now let's go for a deep dive.`,
		images: ['manager-2']
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Some situations can look like personal data breaches, but are not. So let's try and differentiate between them.`,
		images: ['manager-2']
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 'game-2-sort-identify-breaches-1',
		text: `Drag the options into the box they belong in.`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'potential-breach', text: 'Potential personal data breach'
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'usually-not-breach', text: 'Usually not a personal data breach'
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `A form sent from an investigator with initials and date of birth of the patient is visible (not redacted)`, 
				categoryIds: ['potential-breach']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `An email containing salary increase data is sent to Peter G. instead of Peter V.G.`, 
				categoryIds: ['potential-breach']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `A box with clinical study data (including personally identifiable information) gets lost on the post`, 
				categoryIds: ['potential-breach']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `An HR employee gets a report with sensitive personal data as part of their job`, 
				categoryIds: ['usually-not-breach']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: `Hanging a list of department birthdays in the kitchen at work`, 
				categoryIds: ['usually-not-breach']
			}),
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 'game-2-sort-identify-breaches-2',
		text: `How about these ones? Which ones are potential data breaches?`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'potential-breach', text: 'Potential personal data breach'
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'usually-not-breach', text: 'Usually not a personal data breach'
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `One of Genmab’s vendors has experienced a ransomware attack`, 
				categoryIds: ['potential-breach']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `A Genmab employee receives from a CRO an email with clinical trial data (containing personal identifiable information) belonging to another company`, 
				categoryIds: ['potential-breach']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Loss or theft of a locked company phone`, 
				categoryIds: ['usually-not-breach']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `Sending a meeting invitation with time, place, and agenda (no personal identifiable information included) to the wrong person`, 
				categoryIds: ['usually-not-breach']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: `A box with a gift from Genmab to an employee gets lost in the post`, 
				categoryIds: ['usually-not-breach']
			}),
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Now, let's talk a bit more about what to do after you identify a potential personal data breach.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `For that, we'll return to our unlucky employee from Round 1, Benjamin.`
	}),

	Object.assign({}, taskTemplates.branchingStoryTemplate.mainTemplate, {
		taskId: 'game-2-branching-story-benjamin-email',
		maxPoints: 13,
		steps: [
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-30',
				text: `With the best intentions in mind, Benjamin sends out an email to his department (30+ employees) asking them to be extra kind to Anna. He tells the team details about some health issues she's going through without asking her first.
					<br /><br />The damage is done, how can Benjamin limit it?`,
				image: 'benjamin',
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Try to recall the email', 
						nextStepId: 'gb-33',		
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Ask IT to stop the email', 
						nextStepId: 'gb-31',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 7})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 5, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 6, 
						text: 'Forward email to HR and ask what to do', 
						nextStepId: 'gb-39',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-31',
				text: `It's far too late to do that. Now what?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Try to recall the email', 
						nextStepId: 'gb-32',		
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 5, 
						text: 'Forward email to HR and ask what to do', 
						nextStepId: 'gb-42',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-32',
				text: `This might have worked earlier, but after talking to IT; plenty of people will have opened the mail. So what to do instead?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: 'Forward email to HR and ask what to do', 
						nextStepId: 'gb-43',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-33',
				text: `This is a good idea, but it doesn't always work. Also, when people see that an email has been recalled, it might trigger their curiosity. So what else could Benjamin do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask IT to stop the email', 
						nextStepId: 'gb-34',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 5, 
						text: 'Forward email to HR and ask what to do', 
						nextStepId: 'gb-43',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-34',
				text: `It's far too late to do that. Now what?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: 'Forward email to HR and ask what to do', 
						nextStepId: 'gb-43',
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-35',
				text: `This is a good idea, but it doesn't always work. Also, when people see that an email has been recalled, it might trigger their curiosity. So what else could Benjamin do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: 'Forward email to HR and ask what to do', 
						nextStepId: 'gb-43',
					}),					
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-36',
				text: `This might seem simple, but it's actually a pretty good solution. Anything else that should be done?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-44',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-47',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'No. Apart from notifying the DPO, obviously', 
						nextStepId: 'gb-54',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-37',
				text: `An apology is probably needed at some point. But Benjamin should coordinate this through the DPO as Anna might have to be officially notified about the incident. 
					<br /><br />Anything else that should be done?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-45',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-46',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'No. Apart from notifying the DPO, obviously', 
						nextStepId: 'gb-54',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-38',
				text: `It might be necessary at some point. But this is coordinated by the DPO and not the specific department or individual.<br /><br />Anything else that Benjamin should do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-48',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-49',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'No. Apart from notifying the DPO, obviously', 
						nextStepId: 'gb-54',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-39',
				text: `Absolutely not. The prime focus is to limit the damage, and sharing the leak with even more people is never a good idea.<br /><br />What should Benjamin do instead?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Try to recall the email', 
						nextStepId: 'gb-40',		
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Ask IT to stop the email', 
						nextStepId: 'gb-50',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 5, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-40',
				text: `This is a good idea, but it doesn't always work. Also, when people see that an email has been recalled, it might trigger their curiosity. So what else could Benjamin do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-41',
				text: `Absolutely not. The prime focus is to limit the damage, and sharing the leak with even more people is never a good idea.<br /><br />What should Benjamin do instead?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask IT to stop the email', 
						nextStepId: 'gb-50',
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-42',
				text: `Absolutely not. The prime focus is to limit the damage, and sharing the leak with even more people is never a good idea.<br /><br />What should Benjamin do instead?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Try to recall the email', 
						nextStepId: 'gb-43',		
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 4, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-43',
				text: `This is a good idea, but it doesn't always work. Also, when people see that an email has been recalled, it might trigger their curiosity. So what else could Benjamin do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-44',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-44',
				text: `An apology is probably needed at some point. But Benjamin should coordinate this through the DPO as Anna might have to be officially notified about the incident. 
					<br /><br />Anything else that should be done?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-51',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'No. Apart from notifying the DPO, obviously', 
						nextStepId: 'gb-54',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-45',
				text: `It might be necessary at some point. But this is coordinated by the DPO and not the specific department or individual.<br /><br />Anything else that Benjamin should do? `,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-52',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'No. Apart from notifying the DPO, obviously', 
						nextStepId: 'gb-54',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-46',
				text: `This might seem simple, but it's actually a pretty good solution. Anything else that should be done?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-51',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'No. Apart from notifying the DPO, obviously', 
						nextStepId: 'gb-54',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-47',
				text: `It might be necessary at some point. But this is coordinated by the DPO and not the specific department or individual.<br /><br />Anything else that Benjamin should do?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-53',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'No. Apart from notifying the DPO, obviously', 
						nextStepId: 'gb-54',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-48',
				text: `An apology is probably needed at some point. But Benjamin should coordinate this through the DPO as Anna might have to be officially notified about the incident. 
					<br /><br />Anything else that should be done?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-52',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 3})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'No. Apart from notifying the DPO, obviously', 
						nextStepId: 'gb-54',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-49',
				text: `This might seem simple, but it's actually a pretty good solution. Anything else that should be done?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-53',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'No. Apart from notifying the DPO, obviously', 
						nextStepId: 'gb-54',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceTemplate, {
				id: 'gb-50',
				text: `It's far too late to do that. Now what?`,
				options: [
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 1, 
						text: 'Ask the employees to delete the mail', 
						nextStepId: 'gb-36',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 5})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 2, 
						text: 'Apologize to Anna', 
						nextStepId: 'gb-37',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})]
					}),
					Object.assign({}, taskTemplates.branchingStoryTemplate.stepMultipleChoiceOptionTemplate, {
						id: 3, 
						text: 'Report to the authorities', 
						nextStepId: 'gb-38',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})]
					}),
				]
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-51',
				isAutoContinue: false,
				text: `It might be necessary at some point. But this is coordinated by the DPO and not the specific department or individual.`,
				nextStepId: 'gb-55',
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-52',
				isAutoContinue: false,
				text: `This might seem simple, but it's actually a pretty good solution. In addition, you must also remember to notify the DPO of the personal data breach.`,
				nextStepId: 'gb-55'
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-53',
				isAutoContinue: false,
				text: `An apology is probably needed at some point. But Benjamin should coordinate this through the DPO as Anna might have to be officially notified about the incident.`,
				nextStepId: 'gb-55'
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-54',
				text: `Yes, you must always notify the DPO in case of a personal data breach (databreach&commat;genmab.com)`,
			}),
			Object.assign({}, taskTemplates.branchingStoryTemplate.stepInfoTemplate, {
				id: 'gb-55',
				text: `You must notify the DPO of the personal data breach as soon as possible (databreach&commat;genmab.com).`,
			}),
		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Next, we'll examine what the potential consequences of a personal data breach can be.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'game-2-multiple-choice-applicants-info',
		text: `Imagine that for some unfortunate reason, the personal data of hundreds of Genmab's job applicants is suddenly open and available on the internet. What could be the consequences?`,
		shuffleOptions: false,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: `Job applicants could have issues at their current places of work`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: `Genmab could get a bad reputation for not looking after the personal data entrusted to the company`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: `Genmab could have difficulties recruiting in the future`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: `Applicants could start receiving spam email and phishing attacks`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: `The Data Protection Authorities could launch an investigation`
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				isCorrect: true,
				text: `All of the above`
			})

		]
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Most personal data breaches happen because of lack of attention or mistakes we make. But sometimes it can also be because things go too fast or because we don't stop and consider the consequences. Let's explore that.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `The next task is a dialogue where you have to imagine you work in IT. A colleague asks for your help, and you must choose the right course of action.`,
		images: ['manager-2']
	}),

	Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
		taskId: 'game-2-dialogue-hr-portal-access',
		person1: 'person-1',
		person2: 'person-2',
		maxPoints: 7,
		introText: `You work in IT. A colleague calls you late Friday afternoon and asks for your help.`,
		steps: [
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'gd-02',
				text: `I know it's late, but can you give me access to the HR portal?`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Sure thing, I'll give you full access.`, nextStepId: 'gd-03'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: `What kind of access do you need?`, nextStepId: 'gd-04',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 3, text: `Have you created a request in Service Now?`, nextStepId: 'gd-05',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'gd-03',
				text: `Great, thanks.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `You're welcome.`, nextStepId: 'gd-06'
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'gd-04',
				text: `I just need to change some settings so my team can view the data.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Alright, I'll give you access.`, nextStepId: 'gd-03'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: `You have to make a request in Service Now first, so the right people can review and approve.`, nextStepId: 'gd-05',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'gd-05',
				text: `Yes, I already did that. But I cannot wait. I need it done now before the weekend.`,
				options: [
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 1, text: `Okay, I'll give you access.`, nextStepId: 'gd-03'
					}),
					Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
						id: 2, text: `Sorry, you have to wait for the request to be reviewed and approved.`, nextStepId: 'gd-07',
						effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 4})],
					}),
				]
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'gd-06',
				text: `By just giving access, you risk that the colleague accesses personal data he has no business purpose to access. It's a perfect example of how wanting to act fast and skip the existing processes can lead to personal data breaches.`,
				isFinalStep: true,
				showPoints: true,
				type: 'action',
				character: 'employee',
			}),
			Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
				id: 'gd-07',
				text: `Not being able to help is never fun. But this is the best way of protecting personal data.`,
				isFinalStep: true,
				showPoints: true,
				type: 'action',
				character: 'employee',
			}),
		]
	}),

	// Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
	// 	text: `Another example could be this one`,
	// 	images: ['manager-2']
	// }),

	// Object.assign({}, taskTemplates.dialogueTemplate.mainTemplate, {
	// 	taskId: 'game-2-dialogue-hr-system-issues',
	// 	person1: 'person-2-flipped',
	// 	person2: 'person-1-flipped',
	// 	maxPoints: 8,
	// 	introText: `You are having issues with the HR system and you need to change some settings. You have a friend outside Genmab who is a bit of a systems "guru", so you call her and ask for help.`,
	// 	steps: [
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-11',
	// 			text: `I'm not sure I understand what you want, can you share your screen and then I can guide you?`,
	// 			options: [
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 1, text: `Sure thing.`, nextStepId: 'gd-12'
	// 				}),
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 2, text: `Sorry, no. There's some sensitive data here.`, nextStepId: 'gd-18',
	// 					effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 6})],
	// 				}),
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-12',
	// 			text: `You share your screen with her.`,
	// 			type: 'action',
	// 			nextStepId: 'gd-13'
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-13',
	// 			text: `This looks strange, I have never seen this before... You've really messed up the settings! Do you mind if I take a screenshot and use as an example in one of my workshops?`,
	// 			options: [
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 1, text: `Okay.`, nextStepId: 'gd-16'
	// 				}),
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 2, text: `I'm afraid some of the data can't leave the house.`, nextStepId: 'gd-15',
	// 					effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
	// 				}),
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-14',
	// 			text: `Too bad. But let me show you how to fix it.`,
	// 			options: [
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 1, text: `Thanks!`, nextStepId: 'gd-20'
	// 				})				
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-15',
	// 			text: `Oh, I'll of course make sure to obscure all of that.`,
	// 			options: [
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 1, text: `Allright, go ahead then.`, nextStepId: 'gd-16'
	// 				}),
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 2, text: `Sorry, still a no go.`, nextStepId: 'gd-14',
	// 					effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 1})],
	// 				}),
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-16',
	// 			text: `Cool, thanks. Now, let's get those settings fixed for you.`,
	// 			options: [
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 1, text: `Thank you.`, nextStepId: 'gd-20'
	// 				})				
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-17',
	// 			text: `Alright, I can understand that. Now, let's get those settings fixed.`,
	// 			options: [
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 1, text: `Thanks!`, nextStepId: 'gd-20'
	// 				})				
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-18',
	// 			text: `Okay. It'll be difficult to help you then, but I'll see what I can do.`,
	// 			options: [
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 1, text: `Thanks.`, nextStepId: 'gd-19'
	// 				})				
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-19',
	// 			text: `It takes some time, but she manages to guide you to a solution. Also, you didn't create a potential data breach by sharing your screen with an external.`,
	// 			type: 'action',
	// 			isFinalStep: true
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-20',
	// 			text: `She quickly helps you find a solution. What should you do now?`,
	// 			type: 'action',
	// 			options: [
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 1, text: `Mention her in a LinkedIN post to tell my network how cool she is.`, nextStepId: 'gd-22'
	// 				}),
	// 				Object.assign({}, taskTemplates.dialogueTemplate.stepOptionTemplate, {
	// 					id: 2, text: `Report it to the DPO as a potential data breach.`, nextStepId: 'gd-23',
	// 					effects: [Object.assign({}, effectTemplate.mainTemplate, {type: 'points', value: 2})],
	// 				}),			
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-22',
	// 			text: `This could potentially only make things worse. What you need to do is report this incident to the DPO as a potential personal data breach. Even if your friend did not take a screen shot, you still gave her access to data she had no legitimate purpose to look into.`,
	// 			type: 'action',
	// 			isFinalStep: true
	// 		}),
	// 		Object.assign({}, taskTemplates.dialogueTemplate.stepTemplate, {
	// 			id: 'gd-23',
	// 			text: `Yes! You shouldn't have shared your screen with personal data in the first place, but this is the next best thing to do.`,
	// 			type: 'action',
	// 			isFinalStep: true
	// 		}),	
	// 	]
	// }),

	// Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
	// 	text: `Always be mindful of what information you share and with whom. In the case you just saw, sharing information with an external party that has no business reason nor legitimate purpose to look into Genmab's data is a definite no go. In fact, there's one thing you should always do ...`,
	// 	images: ['manager-1']
	// }),

	// Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
	// 	taskId: 'game-2-multiple-choice-always-do',
	// 	text: `What should you always do?`,
	// 	options: [
	// 		Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
	// 			id: 1, isCorrect: true, text: `Double-check adresses in Outlook before pressing send`,
	// 			effects: [
	// 				Object.assign({}, effectTemplate.mainTemplate, {
	// 					type: 'feedback',
	// 					feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
	// 						text: `Exactly! Autofill in Outlook can lead to potential personal data breaches.`
	// 					})
	// 				})
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
	// 			id: 2, text: `Look out for shady types in trenchcoats and sunglasses`,
	// 			effects: [
	// 				Object.assign({}, effectTemplate.mainTemplate, {
	// 					type: 'feedback',
	// 					feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
	// 						text: `I think you've seen too many spy movies :-)`
	// 					})
	// 				})
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
	// 			id: 3, text: `Ask your manager before sharing personal data out of Genmab`,
	// 			effects: [
	// 				Object.assign({}, effectTemplate.mainTemplate, {
	// 					type: 'feedback',
	// 					feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
	// 						text: `That shouldn't be neccessary. But ensure you share it on a "need to know" basis and, if in doubt, of course talk to your manager about it.`
	// 					})
	// 				})
	// 			]
	// 		}),
	// 		Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
	// 			id: 4, text: `Report to the DPO immediately if you send an email to the wrong person`,
	// 			effects: [
	// 				Object.assign({}, effectTemplate.mainTemplate, {
	// 					type: 'feedback',
	// 					feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
	// 						text: `You should only do this if the email contained personal data.`
	// 					})
	// 				})
	// 			]
	// 		})
	// 	]
	// }),

	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		text: `Well done, you've completed the personal data breach game. You are now a bona fide data breach expert! 
			<br /><br />Remember you can try this or the first round again and see if you can improve your score.`,
	}),
]

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'game-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export {
	moduleTasks
};