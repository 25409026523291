import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import TaskIntro from 'components/game/module/task-intro/task-intro';
import Feedback from 'components/ui/feedback/feedback';
import './branching-story-mc.scss';

const BranchingStoryMc = ({fadeLevel, playerStoryStepData, storyStepData, handleCompleteStoryStep}) => {

	/**
	 * Get option ids, shuffle them
	 * @returns {array} optionIds
	 */
	const getOptionIds = () => {
		if (!storyStepData.options) return [];
		return shuffleArray(storyStepData.options.map((option) => {return option.id;}));
	};
	const [optionIds, setOptionIds] = useState(getOptionIds());

	/**
	 * Get selected option id
	 * @returns {number} optionId
	 */
	const getSelectedOptionId = () => {
		const optionId = (playerStoryStepData && playerStoryStepData.optionId
			?  playerStoryStepData.optionId
			: null
		);
		return optionId;
	};

	/* Selected option id, points, feedback */
	const [selectedOptionId, setSelectedOptionId] = useState(getSelectedOptionId());
	const [points, setPoints] = useState(0);
	const [feedbackData, setFeedbackData] = useState(null);

	/* Update selected items if new step */
	useEffect(() => {
		setOptionIds(getOptionIds());
		setSelectedOptionId(getSelectedOptionId());
		setPoints(0);
		setFeedbackData(null);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storyStepData.id]);


	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	const selectOptionId = (optionId) => {
		/* Hide feedback */
		setFeedbackData(null);

		/* Already completed / selected */
		if (playerStoryStepData.isCompleted || selectedOptionId) return;
		
		/* Get selected option data */
		const selectedOptionData = (storyStepData.options 
			? storyStepData.options.find((o) => {return o.id === optionId;})
			: 0
		);
		if (selectedOptionData) {
			setSelectedOptionId(optionId);

			let newPoints = points;
			let newFeedbackData = null;
			if (selectedOptionData.effects && selectedOptionData.effects.length > 0) {
				selectedOptionData.effects.forEach((effect) => {
					if (effect.type === 'points') {
						/* Effect: points */
						newPoints += effect.value;	
					} else {
						/* Effect: feedback, popup, special points, avatar item */
						newFeedbackData = effect.feedback;
					}
				});
			}

			if (newFeedbackData) {
				/* Show feedback */
				setFeedbackData(newFeedbackData);
				setPoints(newPoints);
			} else {
				/* Complete step */
				completeStoryStep(optionId, newPoints);
			}
		}
	};

	/**
	 * Complete story step
	 * @param {number} optionId 
	 */
	const completeStoryStep = (optionId, newPoints) => {
		const selectedOptionData = (storyStepData.options 
			? storyStepData.options.find((o) => {return o.id === optionId;})
			: 0
		);
		if (selectedOptionData) {
			if (!selectedOptionData.nextStepId) {
				/* Option does not complete story step, player must select another option */
				setSelectedOptionId(null);
				setFeedbackData(null);
			} else {
				/* Complete step */
				setFeedbackData(null);
				handleCompleteStoryStep(newPoints, selectedOptionData.nextStepId, optionId);
			}
		}
	};


	return (
		<div className={'BranchingStoryMc' + (fadeLevel ? ' ' + fadeLevel + 'FadeOut' : '')}>
			<div className="BranchingStoryMc-intro">
				<TaskIntro 
					text={storyStepData.text}
					image={storyStepData.image}
				/>
			</div>
			<div className="BranchingStoryMc-options">
				{optionIds.map((optionId) => {
					const optionData = storyStepData.options.find((option) => {return option.id === optionId;});
					if (!optionData) return null;

					const isSelected = (selectedOptionId === optionData.id);
					const isHidden = (!isSelected && playerStoryStepData.isCompleted === true);

					const optionClass = 'BranchingStoryMc-option' 
						+ (isSelected ? ' selected' : '' + (isHidden ? ' hidden' : ''));
					return (
						<div 
							key={optionData.id} 
							className={optionClass} 
							onClick={() => {selectOptionId(optionData.id);}}
						>
							<span>{optionData.text}</span>
						</div>
					);
				})}
			</div>
			{feedbackData && 
				<Feedback 
					feedbackData={feedbackData} 
					onClick={() => {completeStoryStep(selectedOptionId, points);}} 
				/>}
		</div>
	);
};

BranchingStoryMc.propTypes = {
	fadeLevel: PropTypes.string,
	playerStoryStepData: PropTypes.object,
	storyStepData: PropTypes.object.isRequired,
	handleCompleteStoryStep: PropTypes.func.isRequired
};

export default BranchingStoryMc;
