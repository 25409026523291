import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import SelectRegion from 'components/select-region/select-region';
import Home from './home/home';
import Intro from 'components/intro/intro';
import Highscore from './highscore/highscore';
import ModuleController from './module/module-controller';
import './game.scss';

const Game = ({deviceInfo, userData, gameData, scrollToTop, updateUser, resetGameData, handleLogout}) => {

	/* Intro seen */
	const [introSeen, setIntroSeen] = useState((userData.introSeen ? true : false));

	/* Navigation */
	const [navigation, setNavigation] = useState({page: 'home', moduleId: null});
	const [navigationHistory, setNavigationHistory] = useState({page: null, moduleId: null});


	/**
	 * Go to a new page
	 * @param {string} page 
	 */
	const handleGoToPage = (page, moduleId = null) => {
		setNavigationHistory({page: navigation.page, moduleId: navigation.moduleId});
		setNavigation({page, moduleId});
		scrollToTop();
	};

	/* Go to prev page */
	const handleGoToPrevPage = () => {
		if (navigationHistory.page) {
			setNavigation({page: navigationHistory.page, moduleId: navigationHistory.moduleId});
			scrollToTop();
		}
	};

	/**
	 * Select region
	 * @param {string} regionId 
	 */
	const selectRegion = (regionId) => {
		updateUser({regionId}).then(() => {
			handleGoToPage('home');	
		});
	};

	/**
	 * Mark intro as seen
	 */
	const handleIntroSeen = () => {
		setIntroSeen(true);
		updateUser({introSeen: true});
	};

	/* Get page component */
	let PageComponent = Home;
	if (!userData.regionId || navigation.page === 'region') PageComponent = SelectRegion;
	if (userData.regionId && navigation.page === 'home' && !introSeen) PageComponent = Intro;
	if (navigation.page === 'module') PageComponent = ModuleController;
	if (navigation.page === 'highscore') PageComponent = Highscore;
	
	return (
		<div className={'Game ' + navigation.page}>
			<DndProvider options={HTML5toTouch}>
				<PageComponent 
					deviceInfo={deviceInfo}
					moduleId={navigation.moduleId}
					userData={userData}
					gameData={gameData}
					scrollToTop={scrollToTop}
					selectRegion={selectRegion}
					updateUser={updateUser}
					resetGameData={resetGameData}
					handleGoToPage={handleGoToPage}
					handleGoToPrevPage={handleGoToPrevPage}
					handleIntroSeen={handleIntroSeen}
					handleLogout={handleLogout}
				/>
			</DndProvider>
		</div>
	);
};

Game.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	resetGameData: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Game;
