import React from 'react';
import PropTypes from 'prop-types';
import {modulesData, moduleComponents} from 'data/modules-data';
import {facilitatorUiTexts} from 'data/ui-texts';
import {getAverageMistakesAndPerfectScores} from 'helpers/facilitator-helper';
import './facilitator-task-popup.scss';

const FacilitatorTaskPopup = ({ 
	regionId,
	moduleId,
	taskId, 
	deviceInfo,
	players, 
	setTaskId,
	closePopup,	
}) => {

	/* Get module and tasks data */
	const moduleData = modulesData.find((moduleData) => {return moduleData.id === moduleId;});
	const moduleTasks = moduleData.tasks.filter((task) => {return (task.isSolveToContinue);});

	/* Get task index from task id */
	const taskIndex = (moduleTasks ? moduleTasks.findIndex((t) => {return t.taskId === taskId;}) : -1);

	/* Get task data & player task data (from most recent session) */
	const taskData = moduleTasks[taskIndex];


	/* Get active players in region */
	const playersInRegion = players.filter((player) => {
		return (
			(player.regionId === regionId || regionId === 'all') && 
			player.modules &&
			player.modules.length > 0 &&
			player.modules.some((m) => {return m.moduleId === moduleId;})
		);
	});

	/* Average mistakes and perfect scores */
	const {avrMistakes, avrPerfectScores} = 
		getAverageMistakesAndPerfectScores(playersInRegion, moduleId, taskId);

	/* Get task component */
	let TaskComponent = null;
	if (
		taskIndex >= 0 && 
		moduleTasks.length > taskIndex && 
		moduleComponents.hasOwnProperty(moduleTasks[taskIndex].type)
	) {
		TaskComponent = moduleComponents[moduleTasks[taskIndex].type];
	}

	/**
	 * Go to next / prev task
	 * @param {number} nextTaskIndex 
	 */
	const handleGoToTask = (nextTaskIndex) => {
		if (moduleTasks.length > nextTaskIndex) {
			const nextTaskData = moduleTasks[nextTaskIndex];
			if (nextTaskData && nextTaskData.taskId) setTaskId(nextTaskData.taskId);
		}
	};

	return (
		<div className='FacilitatorTaskPopup' onClick={(e) => {e.stopPropagation();}}>
			<div className='FacilitatorTaskPopup-header'>
				<div className='FacilitatorTaskPopup-title'><span>{moduleData.title}</span></div>
				<div className='FacilitatorTaskPopup-closeBtn' onClick={() => {closePopup();}}/>
			</div>
			<div className='FacilitatorTaskPopup-taskSidebarBody'>
				<div className='FacilitatorTaskPopup-task'>
					{TaskComponent && moduleData && 
						<TaskComponent
							isShowingFeedbackOrPopup={false}
							moduleData={moduleData}
							taskData={taskData}
							deviceInfo={deviceInfo}
							setShowPointsTrack={() => {}}
							handleInstantTaskEffects={() => {}}
							handleCompleteIntro={() => {}}
							handleCompleteTask={() => {}}
							handleGoToTask={() => {}}
							updateLoggedTime={() => {}}
							isFacilitator={true}
						/>
					}
				</div>
				
				<div className='FacilitatorTaskPopup-statistics'>
					<div className='FacilitatorTaskPopup-statisticsHeader'>{facilitatorUiTexts.avrMistakes}
						<div className='FacilitatorTaskPopup-statisticsResult'>
							{avrMistakes}
						</div>
					</div>
					<div className='FacilitatorTaskPopup-statisticsHeader'>{facilitatorUiTexts.perfectScores}
						<div className='FacilitatorTaskPopup-statisticsResult'>
							{avrPerfectScores}
						</div>
					</div>
					<div className='FacilitatorTaskPopup-taskSidebarFooter'>
						<div className='FacilitatorTaskPopup-previous' 
							onClick={() => {handleGoToTask(taskIndex - 1);}}/>
						<div className='FacilitatorTaskPopup-taskText'>
							{facilitatorUiTexts.task + ' ' + (taskIndex + 1) + '/' + moduleTasks.length}
						</div>
						<div className='FacilitatorTaskPopup-next' 
							onClick={() => {handleGoToTask(taskIndex + 1);}}/>
					</div>
				</div>
			</div>

		</div>
	);

};

FacilitatorTaskPopup.propTypes = {
	regionId: PropTypes.string.isRequired,
	moduleId: PropTypes.string.isRequired,
	taskId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	players: PropTypes.array.isRequired,
	setTaskId: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
};

export default FacilitatorTaskPopup;
