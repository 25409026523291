
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
// import {getModuleMaxPoints} from 'helpers/points-helper';
import {getPlayerModuleSessionData} from 'helpers/game-helper';
import {gameUiTexts} from 'data/ui-texts';
import {moduleComponents} from 'data/modules-data';
// import PointsTrack from 'components/ui/points-track/points-track';
import Button from 'components/ui/button/button';
import ModuleNav from 'components/dev-tools/module-nav';
import Instructions from 'components/game/module/instructions/instructions';
import Feedback from 'components/ui/feedback/feedback';
import Popup from 'components/ui/popup/popup';
import './module.scss';

const Module = (props) => {
	const {
		hasQueuedEffects,
		deviceInfo,
		// gameData,
		moduleData, 
		taskData, 
		playerData,
		feedbackData,
		popupData,
		handleGoToTask,
		handleGoToPage,
		handleInstantTaskEffects,
		handleCompleteTask,
		updateLoggedTime,
		processQueuedEffects,
		handleCompleteModuleSession
	} = props;

	/* Get player data for this module session */
	const playerModuleSession = getPlayerModuleSessionData(playerData, moduleData.id);

	/* Get player data for the module session task */
	const playerTaskData = (playerModuleSession && playerModuleSession.tasks
		? playerModuleSession.tasks.find((t) => {return t.taskId === taskData.taskId;})
		: null
	);

	/* Get max points for module */
	// const maxPoints = getModuleMaxPoints(moduleData.id);

	/* Get next module task id */
	const taskIndex = moduleData.tasks.findIndex((task) => {return task.id === taskData.id;});
	const nextTaskId = (taskIndex + 1 < moduleData.tasks.length 
		? moduleData.tasks[taskIndex + 1].id
		: null
	);

	/* Toggle points track, update when new module task */
	const [showPointsTrack, setShowPointsTrack] = useState(false);

	/* Toggle next btn, update on new module task */
	const [nextBtnIsActive, setNextBtnIsActive] = useState(false);
	const updateNextBtnStatus = () => {
		const newNextBtnIsActive = (
			taskData.type !== 'intro' && 
			(taskData.isSolveToContinue === false || (playerTaskData && playerTaskData.isCompleted)) 
				? true : false
		);
		if (newNextBtnIsActive) setNextBtnIsActive(newNextBtnIsActive);
	};

	useEffect(() => {
		/* Hide / show next button */
		if (nextBtnIsActive) {
			setNextBtnIsActive(false);
		} else {
			updateNextBtnStatus();
		}
		
		/* Hide / show points track */
		let showPoints = (taskData.showPoints === true ? true : false);
		if (taskData.hasOwnProperty('showPointsIfCompleted') && taskData.showPointsIfCompleted !== showPoints) {
			const playerModuleSession = getPlayerModuleSessionData(playerData, moduleData.id);
			const playerTaskData = (playerModuleSession && playerModuleSession.tasks
				? playerModuleSession.tasks.find((t) => {return t.taskId === taskData.taskId;})
				: null
			);
			const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);
			if (isCompleted) showPoints = taskData.showPointsIfCompleted;
		}
		setShowPointsTrack(showPoints);

		/* Scroll to top */
		window.scrollTo({top: 0});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	useEffect(() => {
		updateNextBtnStatus();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextBtnIsActive, playerTaskData]);

	/* Next button */
	const showNextBtn = (
		nextBtnIsActive &&
		!hasQueuedEffects && 
		!feedbackData && 
		!popupData
	);
	let nextBtnTypes = ['next'];
	if (playerTaskData && playerTaskData.isCompleted) {
		/* Animate next button when completing a task */
		nextBtnTypes.push('animate');
	} else {
		/* Fade-in next button on welcome / instructions / image pages */
		if (!taskData.isSolveToContinue) {
			nextBtnTypes.push('delay');
		}
	}
	let nextBtnText = null;
	if (taskData.nextBtnTypes) nextBtnTypes = taskData.nextBtnTypes;
	if (taskData.nextBtnText) nextBtnText = taskData.nextBtnText;


	/* Task type component */
	let TaskComponent = Instructions;
	if (moduleComponents.hasOwnProperty(taskData.type)) TaskComponent = moduleComponents[taskData.type];
	
	return (
		<div className={'Module ' + taskData.type  
			+ (deviceInfo && deviceInfo.orientation ? ' ' + deviceInfo.orientation : '')
			+ ' ' + (taskData.background ? taskData.background : moduleData.background)}
		>
			<TaskComponent 
				isShowingFeedbackOrPopup={(feedbackData || popupData ? true : false)}
				deviceInfo={deviceInfo}
				playerData={playerData}
				playerTaskData={playerTaskData}
				moduleData={moduleData} 
				taskData={taskData}
				setShowPointsTrack={setShowPointsTrack}
				updateLoggedTime={updateLoggedTime}
				handleInstantTaskEffects={handleInstantTaskEffects}
				handleCompleteTask={handleCompleteTask}
				handleGoToTask={handleGoToTask}
			/>
			<div className="Module-header">
				{/* Home button */}
				{showPointsTrack && <div className="Module-homeBtn" onClick={() => {handleGoToPage('home');}} />}

				{/* Points track */}
				{/* {showPointsTrack && 
					<div className="Module-pointsTrack">
						<PointsTrack 
							deviceInfo={deviceInfo}
							points={(playerModuleSession && playerModuleSession.points 
								? playerModuleSession.points : 0)} 
							maxPoints={maxPoints} 
						/>
					</div>
				} */}

				{/* Highscore button */}
				{showPointsTrack && 
					<div className="Module-highscoreBtn" onClick={() => {handleGoToPage('highscore');}} />}
			</div>

			{/* Next task button */}
			{(showNextBtn && nextTaskId) && <div className={'Module-nextBtn ' + nextBtnTypes.join(' ')}>
				<Button 
					classes={nextBtnTypes} 
					text={nextBtnText}
					onClick={() => {handleGoToTask(nextTaskId);}} />
			</div>}

			{/* Finish module button */}
			{(showNextBtn && !nextTaskId) && <div className="Module-nextBtn finish">
				<Button 
					classes={['white']} 
					text={gameUiTexts.finish}
					onClick={() => {handleCompleteModuleSession();}} />
			</div>}

			{/* Developer module navigation */}
			{(appConfig.env !== 'production') && 
				<ModuleNav moduleData={moduleData} taskId={taskData.id} handleGoToTask={handleGoToTask} />
			}

			{/* Feedback */}
			{feedbackData && 
				<Feedback 
					feedbackData={feedbackData} 
					onClick={() => {processQueuedEffects();}} 
				/>
			}

			{/* Popup */}
			{popupData &&
				<Popup 
					type={(popupData.type ? popupData.type : null)}
					title={(popupData.title ? popupData.title : null)}
					text={(popupData.text ? popupData.text : null)}
					togglePopup={processQueuedEffects}
				/>
			}
		</div>
	);
};

Module.propTypes = {
	hasQueuedEffects: PropTypes.bool.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	// gameData: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	feedbackData: PropTypes.object,
	popupData: PropTypes.object,
	handleGoToTask: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	processQueuedEffects:PropTypes.func.isRequired,
	handleCompleteModuleSession: PropTypes.func.isRequired,
};

export default Module;
