import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {
	createNewPlayerModule,
	getPlayerModulesData,
	getPlayerModuleSessionData,
	starNewSession, 
	getTimeSinceLastActivity, 
	getLastPlayedTaskId, 
	checkIfAllTasksAreCompleted,
	checkIfModuleSessionIsCompleted,
	getNewStreakData
} from 'helpers/game-helper';
// import {getModuleMaxPoints, getNumberOfFilledStars} from 'helpers/points-helper';
import {errorUiTexts} from 'data/ui-texts';
import {modulesData} from 'data/modules-data';
import Loading from 'components/loading/loading';
import Module from './module';
import Results from './results/results';
// import ImageLoader from 'components/ui/image-loader/image-loader';

class ModuleController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			showResults: false,
			isStartingNewSession: false,
			taskId: null,
			feedbackData: null,
			popupData: null,
			queuedEffects: [],
			streakType: 'error', // error / success
			streakValue: 0,
			loggedTime: 0,
			timestamp: Date.now(),
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Get player modules data (all modules) */
		let playerModulesData = getPlayerModulesData(this.props.userData);

		/* Get player data for this module */
		let moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;});

		/* Check if module session is completed */
		const moduleSession = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);
		const moduleSessionIsCompleted = checkIfModuleSessionIsCompleted(moduleSession);

		/* Show results if module session is completed (and is not intro module) */
		this.setState({showResults: (moduleSessionIsCompleted && this.props.moduleId !== 'intro' ? true : false)});

		if (moduleIndex < 0) {
			/* Player has just started first session in module, create player module data */
			playerModulesData.push(createNewPlayerModule(this.props.moduleId));
			this.props.updateUser({modules: playerModulesData}).then(() => {
				/* Go to first task in module, automatically sets isLoading to false */
				this.handleGoToTask(playerModulesData[playerModulesData.length - 1].sessions[0].currentTaskId);
			});
		} else {
			/* Player has alrady started module, go to last played task, automatically sets isLoading to false */
			const taskId = getLastPlayedTaskId(this.props.moduleId, this.props.userData);
			if (taskId) this.handleGoToTask(taskId);
		}
	};


	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Update logged time for session */
		const loggedTime = this.updateLoggedTime();
		let playerModulesData = getPlayerModulesData(this.props.userData);
		const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;});
		let playerSessionData = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);
		const sessionIsCompleted = checkIfModuleSessionIsCompleted(playerSessionData);
		if (playerSessionData && !sessionIsCompleted) {
			if (!playerSessionData.hasOwnProperty('milisecondsPlayed')) playerSessionData.milisecondsPlayed = 0;
			playerSessionData.milisecondsPlayed = playerSessionData.milisecondsPlayed + loggedTime;	
			playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] 
				= playerSessionData;
			this.props.updateUser({modules: playerModulesData});	
		}
		
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);
	};
	

	/**
	 * Update logged time
	 * @param {bool} resetLoggedTime
	 */
	updateLoggedTime = (resetLoggedTime = false) => {
		/* Get number of miliseconds since last update */
		const miliseconds = getTimeSinceLastActivity(this.state.timestamp);
		const newLoggedTime = this.state.loggedTime + miliseconds;

		this.setState({loggedTime: (resetLoggedTime ? 0 : newLoggedTime), timestamp: Date.now()});
		return newLoggedTime;
	};


	/**
	 * Update current task id for module in player data
	 */
	updateCurrentTaskId = (taskId) => {
		if (!taskId) return;

		/* Get player modules data */
		let playerModulesData = getPlayerModulesData(this.props.userData);

		/* Get module index in player data */
		const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;});

		/* Get module session */
		let moduleSession = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);
		if (moduleSession) {
			moduleSession.currentTaskId = taskId;
			playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] = moduleSession;
			this.props.updateUser({modules: playerModulesData});	
		} else {
			console.error('This should not happen!');
		}
	};


	/**
	 * Go to module task
	 * @param {number} taskId 
	 */
	handleGoToTask = (taskId) => {
		/* Log time */
		this.updateLoggedTime();

		/* Go to task */
		this.setState({taskId, isLoading: false}, () => {
			/* Scroll to page top */
			this.props.scrollToTop();

			/* Update current task in player data */
			this.updateCurrentTaskId(taskId);
		});
	};

	/**
	 * Handle instant task effects 
	 * They are triggered during a task (e.g. by selecting an option in multiple choice)
	 * @param {array} effects 
	 */
	handleInstantTaskEffects = (effects) => {
		/* Get queued effects */
		let queuedEffects = (this.state.queuedEffects && this.state.queuedEffects.length > 0 
			? [...this.state.queuedEffects] 
			: []
		);

		/* Get streak data */
		let newStreakType = this.state.streakType;
		let newStreakValue = this.state.streakValue;

		/* Loop over effects */
		effects.forEach((effect) => {
			if (
				(effect.type === 'feedback' && effect.feedback) ||
				(effect.type === 'popup' && effect.popup)
			) {
				/* Feedback / popup */
				queuedEffects.push(effect);
			}			
			
			if (effect.type === 'streak') {
				/* Success / error Streak */
				const newStreakData = 
					getNewStreakData(this.state.streakType, this.state.streakValue, effect.isCorrectAnswer);
				newStreakType = newStreakData.newStreakType;
				newStreakValue = newStreakData.newStreakValue;
				// TODO: handle effects triggerede by streaks
			}
		});
	
		if (queuedEffects.length > 0) {
			/* Updates treak data & start showing queued instant effects */
			this.setState({queuedEffects, streakType: newStreakType, streakValue: newStreakValue}, () => {
				this.processQueuedEffects(false);
			});
		} else {
			/* Update streak data */
			this.setState({streakType: newStreakType, streakValue: newStreakValue});
		}
	};

	/**
	 * Process queued instant effects
	 */
	processQueuedEffects = (delayNextEffect = true) => {
		/* Close all effects */
		this.setState({feedbackData: null, popupData: null}, () => {
			let queuedEffects = [...this.state.queuedEffects];
			if (queuedEffects.length === 0) {
				/* Queue is empty */
				const moduleData = modulesData.find((m) => {return m.id === this.props.moduleId;});
				const taskIndex = moduleData.tasks.findIndex((task) => {return task.id === this.state.taskId;});
				const playerModuleSessionData = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);
				const moduleSessionIsCompleted = checkIfModuleSessionIsCompleted(playerModuleSessionData);
				if (moduleSessionIsCompleted && taskIndex + 1 >= moduleData.tasks.length) {
					/* Last task in module completed, show results */
					this.setState({showResults: true});
				}
				
			} else {
				/* Display next effect */
				if (
					queuedEffects[0].type === 'feedback' ||
					queuedEffects[0].type === 'popup'
				) {
					/* Feedback or popup */
					const newQueuedEffects = queuedEffects.slice(1);
					const feedbackData = (queuedEffects[0].type === 'feedback' ? queuedEffects[0].feedback : null);
					const popupData = (queuedEffects[0].type === 'popup' ? queuedEffects[0].popup : null);

					const delay = (delayNextEffect 
						? (queuedEffects[0].type === 'feedback' ? appConfig.feedbackDelay : appConfig.popupDelay)
						: 0
					);
					if (this.timeout) clearTimeout(this.timeout);
					this.timeout = setTimeout(() => {
						this.setState({feedbackData, popupData, queuedEffects: newQueuedEffects});
					}, delay);	
				}
				if (queuedEffects[0].type === 'avatar-item') {
					const newQueuedEffects = queuedEffects.slice(1);
					const feedbackData = {
						type: 'avatar-item', 
						itemId: queuedEffects[0].avatarItem.itemId,
						text: queuedEffects[0].avatarItem.text,
					};
					const delay = (delayNextEffect ? appConfig.feedbackDelay : 0);
					if (this.timeout) clearTimeout(this.timeout);
					this.timeout = setTimeout(() => {
						this.setState({feedbackData, queuedEffects: newQueuedEffects});
					}, delay);	
				}
			}
		});
	};

	/**
	 * Handle a completed task
	 * @param {string} type 
	 * @param {number} points 
	 * @param {number} errors 
	 * @param {*object} taskData 
	 */
	handleCompleteTask = (type, points, errors, effects, taskData) => {	
		/* Get logged time, reset */
		const loggedTime = this.updateLoggedTime(true);

		/* Get streak data */ 
		let newStreakType = this.state.streakType;
		let newStreakValue = this.state.streakValue;

		/* Get task data */	
		const moduleData = modulesData.find((m) => {return m.id === this.props.moduleId;});
		const taskId = (moduleData && moduleData.tasks.some((task) => {return task.id === this.state.taskId;})
			? moduleData.tasks.find((task) => {return task.id === this.state.taskId;}).taskId
			: null
		);
		if (!taskId) {
			console.error('Completing task - but no task data found. Should not happen!');
			console.error('Task id: ', this.state.taskId);
			return;
		}

		/* Get player data */
		let playerModulesData = getPlayerModulesData(this.props.userData);
		const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;});
		let playerSessionData = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);

		if (!playerSessionData) {
			console.error('Completing task - but no module / session found in database. Should not happen!');
			return;
		}

		/* Update logged time for session */
		if (!playerSessionData.hasOwnProperty('milisecondsPlayed')) playerSessionData.milisecondsPlayed = 0;
		playerSessionData.milisecondsPlayed = playerSessionData.milisecondsPlayed + loggedTime;	

		/* Prepare array of effects to show */
		let queuedEffects = [];

		/* Handle effects */
		effects.forEach((effect) => {
			if (
				(effect.type === 'feedback' && effect.feedback) ||
				(effect.type === 'popup' && effect.popup)
			) {
				/* Feedback / popup */
				queuedEffects.push(effect);
			}			
			
			if (effect.type === 'streak') {
				/* Success / error Streak */
				const newStreakData = 
					getNewStreakData(this.state.streakType, this.state.streakValue, effect.isCorrectAnswer);
				newStreakType = newStreakData.newStreakType;
				newStreakValue = newStreakData.newStreakValue;
			}
		});

		/* Update total points in module */
		playerSessionData.points += points;

		/* Add completed task to module */
		let taskObj = Object.assign({}, {
			isCompleted: true,
			taskId: taskId,
			type: type,
			points: points,
			errors: errors
		}, (taskData ? taskData : {}));
		if (!playerSessionData.tasks) playerSessionData.tasks = [];
		playerSessionData.tasks.push(taskObj);

		/* Check if all tasks in module are complete */
		const moduleTasksAreCompleted = checkIfAllTasksAreCompleted(this.props.moduleId, playerSessionData);
		playerSessionData.moduleTasksAreCompleted = moduleTasksAreCompleted;
		playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] = playerSessionData;

		if (moduleTasksAreCompleted) {
			/* Log module max points */
			playerModulesData[moduleIndex].maxPoints = Math.max(
				(playerModulesData[moduleIndex].maxPoints ? playerModulesData[moduleIndex].maxPoints : 0),
				playerSessionData.points
			);

			/* Log module max stars */
			// playerModulesData[moduleIndex].maxStars = Math.max(
			// 	(playerModulesData[moduleIndex].maxStars ? playerModulesData[moduleIndex].maxStars : 0),
			// 	getNumberOfFilledStars(
			// 		playerSessionData.points,
			// 		getModuleMaxPoints(this.props.moduleId)
			// 	)
			// );
		}

		/* Update player data */
		this.props.updateUser({modules: playerModulesData});

		if (queuedEffects.length > 0) {
			/* Update streak data & start showing queued effects */
			this.setState({queuedEffects, streakType: newStreakType, streakValue: newStreakValue}, () => {
				this.processQueuedEffects(false);
			});
		} else {
			/* Update streak data */
			this.setState({streakType: newStreakType, streakValue: newStreakValue});
		}
	};

	/**
	 * Complete module session
	 */
	handleCompleteModuleSession = () => {
		/* Get logged time, reset */
		const loggedTime = this.updateLoggedTime(true);

		/* Get player data */
		let playerModulesData = getPlayerModulesData(this.props.userData);
		const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === this.props.moduleId;});
		let playerSessionData = getPlayerModuleSessionData(this.props.userData, this.props.moduleId);
		
		/* Update logged time & isCompleted flag */
		if (!playerSessionData.hasOwnProperty('milisecondsPlayed')) playerSessionData.milisecondsPlayed = 0;
		playerSessionData.milisecondsPlayed = playerSessionData.milisecondsPlayed + loggedTime;
		playerSessionData.isCompleted = true;
		playerModulesData[moduleIndex].sessions[playerModulesData[moduleIndex].sessions.length - 1] = playerSessionData;

	
		/* No new badges, go directly to results */
		this.props.updateUser({modules: playerModulesData});
		if (this.props.moduleId !== 'intro') this.setState({showResults: true});
		
	};

	/**
	 * Handle reset module
	 */
	handleStartNewSession = () => {
		this.setState({isStartingNewSession: true, loggedTime: 0, timestamp: Date.now()}, () => {
			const playerModulesData = starNewSession(this.props.moduleId, this.props.userData);
			if (playerModulesData) {
				/* Reset module */
				this.props.updateUser({modules: playerModulesData}).then(() => {
					const moduleData = modulesData.find((m) => {return m.id === this.props.moduleId;});
					const taskId = (moduleData && moduleData.tasks && moduleData.tasks.length > 0 
						? moduleData.tasks[0].id
						: null
					);
					/* Hide result, go to first task in module */
					this.setState({
						isStartingNewSession: false,
						showResults: false,
						taskId: taskId,
					});
				});
			} else {
				/* Module not found (should not happen) */
				this.setState({isStartingNewSession: false});
			}
		});
	};


	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading type="loading-module" deviceInfo={this.props.deviceInfo} />
			);
		};

		/* Get module and module task data */
		const moduleData = modulesData.find((m) => {return m.id === this.props.moduleId;});

		/* Module not found */
		if (!moduleData) {
			return <div>{errorUiTexts.unknownModuleId}: {this.props.moduleId}</div>;
		}

		/* Show results page */
		if (this.state.showResults) {
			return (
				<Results 
					isStartingNewSession={this.state.isStartingNewSession}
					moduleData={moduleData} 
					userData={this.props.userData} 
					handleGoToPage={this.props.handleGoToPage}
					handleStartNewSession={this.handleStartNewSession}
				/>
			);
		}

		/* Get task data */
		const taskData = (moduleData 
			? moduleData.tasks.find((task) => {return task.id === this.state.taskId;})
			: null
		);
		if (!taskData) {
			console.error(this.props.moduleId);
			console.error(this.state.taskId);
			return (
				<div>{errorUiTexts.unknownModuleTaskId}: {this.state.taskId}</div>
			);
		}
		
		/* Render component */
		return (
			<>
				<Module 
					hasQueuedEffects={this.state.queuedEffects.length > 0 || this.state.queuedEffects.length > 0}
					deviceInfo={this.props.deviceInfo}
					gameData={this.props.gameData}
					moduleData={moduleData} 
					taskData={taskData}
					playerData={this.props.userData}
					feedbackData={this.state.feedbackData}
					popupData={this.state.popupData}
					updateLoggedTime={this.updateLoggedTime}
					handleGoToTask={this.handleGoToTask}
					handleInstantTaskEffects={this.handleInstantTaskEffects}
					handleCompleteTask={this.handleCompleteTask}
					handleGoToPage={this.props.handleGoToPage}
					processQueuedEffects={this.processQueuedEffects}
					handleCompleteModuleSession={this.handleCompleteModuleSession}
				/>
				{/* <ImageLoader type={'module-' + this.props.moduleId} /> */}
			</>
		);
	}
}

ModuleController.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	moduleId: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	scrollToTop: PropTypes.func.isRequired
};

export default ModuleController;
